<template>
  <div v-if="isOpenFilter" class="filterMobile" @click.self="toggleFilter">
    <div class="filterMobile__content">
      <div class="filterMobile__header">
        Фильтры
        <CloseSvg @click="toggleFilter" />
      </div>
      <div
        :class="['filterMobile__item', { 'filterMobile__item--active': filter == 'profile' }]"
        @click="update('profile')"
      >
        Профиль
      </div>
      <div
        :class="['filterMobile__item', { 'filterMobile__item--active': filter == 'socials' }]"
        @click="update('socials')"
      >
        Социальные сети
      </div>
      <div
        :class="['filterMobile__item', { 'filterMobile__item--active': filter == 'about' }]"
        @click="update('about')"
      >
        О себе
      </div>
      <div
        :class="['filterMobile__item', { 'filterMobile__item--active': filter == 'interests' }]"
        @click="update('interests')"
      >
        Интересы
      </div>
      <div
        :class="['filterMobile__item', { 'filterMobile__item--active': filter == 'education' }]"
        @click="update('education')"
      >
        Образование
      </div>
      <div
        :class="['filterMobile__item', { 'filterMobile__item--active': filter == 'career' }]"
        @click="update('career')"
      >
        Карьера
      </div>
      <div
        :class="['filterMobile__item', { 'filterMobile__item--active': filter == 'safety' }]"
        @click="update('safety')"
      >
        Безопасность
      </div>

      <CloseModalSvg class="filterMobile__close" @click="toggleFilter" />
    </div>
  </div>
</template>

<script setup>
// Svg
import CloseSvg from '@/components/svg/CloseSvg'
import CloseModalSvg from '@/components/svg/CloseModalSvg'

// Helpers
import isMobile from '@/mixins/isMobile'
import useFilter from '@/mixins/useFilter'

import { ref } from 'vue'

const filter = ref('profile')
const emit = defineEmits(['update'])
let { isOpenFilter, toggleFilter } = useFilter()

const update = type => {
  filter.value = type
  emit('update', type)
  toggleFilter()
}
</script>

<style scoped>
.filterMobile {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 20;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}
.filterMobile__content {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #2a3137;
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.55);
  border-radius: 20px 20px 0px 0px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.filterMobile__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  color: #ffda7e;
  padding: 16px;
}
.filterMobile__item {
  padding: 17px 24px;
}
.filterMobile__item--active {
  background: #343c42;
  font-weight: 600;
}
.filterMobile__close {
  display: block;
  margin: 40px auto;
}
</style>
