<template>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36ZM8.93656 8.93827C10.0056 7.86925 11.2563 7.27092 12.7281 7.12435C12.952 7.10206 15.443 7.08873 18.2638 7.09468C23.2484 7.10527 23.4054 7.10834 23.8496 7.20473C25.1225 7.48086 26.1033 7.99675 26.9943 8.85871C27.9594 9.79222 28.5612 10.9213 28.8148 12.2741C28.8992 12.7243 28.9047 13.0727 28.9047 18.0019C28.9047 22.8074 28.9107 23.5238 28.7264 24.1872C28.6874 24.3279 28.6397 24.4661 28.5817 24.6405C27.9679 26.4843 26.4524 27.9965 24.6218 28.5919C24.4493 28.648 24.3122 28.6941 24.1729 28.7319C23.5099 28.9122 22.7962 28.9064 17.9762 28.9064C13.1941 28.9064 12.6633 28.8987 12.2696 28.8241C9.65174 28.3279 7.67418 26.3504 7.17804 23.7325C7.10336 23.3387 7.09574 22.8066 7.09574 18.0019C7.09574 13.1716 7.08887 12.4795 7.26525 11.8378C7.30235 11.7029 7.34756 11.5701 7.40265 11.4015C7.71233 10.4539 8.19333 9.6815 8.93656 8.93827ZM25.0457 11.5886C24.9261 11.3107 24.615 10.9974 24.3477 10.8857C24.1299 10.7947 23.7301 10.7557 23.5363 10.8066C22.3154 11.1272 22.1352 12.7379 23.2586 13.2876C24.1176 13.7079 25.0757 13.1268 25.1333 12.1507C25.148 11.9023 25.1294 11.7831 25.0457 11.5886ZM23.2025 20.1728C24.6459 16.8173 22.4893 12.9619 18.8632 12.4152C16.6913 12.0878 14.5253 13.0655 13.3175 14.9184C12.3743 16.3654 12.1564 18.3091 12.7582 19.9081C13.7693 22.5942 16.6525 24.1172 19.3989 23.416C20.4557 23.1461 21.232 22.6942 22.0265 21.8863C22.5693 21.3343 22.9272 20.8129 23.2025 20.1728ZM14.746 16.4275C15.2176 15.379 16.2123 14.602 17.3612 14.3843C17.8049 14.3002 18.5101 14.3206 18.9349 14.4296C19.4675 14.5664 20.065 14.9002 20.4861 15.2963C21.3051 16.0668 21.6916 17.0228 21.6277 18.1198C21.5947 18.6851 21.5046 19.0496 21.2825 19.5147C20.5506 21.0474 18.7816 21.8607 17.1135 21.4314C16.1138 21.1741 15.1716 20.3786 14.746 19.4325C14.3244 18.4953 14.3244 17.3648 14.746 16.4275Z"
      fill="white"
    />
  </svg>
</template>
