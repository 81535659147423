<template>
  <section class="news">
    <div class="news__content">
      <Note v-if="isMobile" />

      <div v-if="barbershops.length > 0 && managers">
        <div class="block">
          <div class="block__header">Руководители</div>
          <Worker v-for="manager in managers" :key="manager.id" :worker="manager" />
        </div>

        <div class="block">
          <div class="block__header">Сети компаний в Екатеринбурге</div>
          <Salon
            v-for="barbershop in barbershops"
            :id="barbershop.id"
            :key="barbershop.id"
            :name="barbershop.name"
            :workers="barbershop.workers"
            :year="barbershop.year"
            :offices="barbershop.offices"
            :link="barbershop.link"
          />
        </div>

        <div class="block">
          <div class="block__header">Сети компаний в других городах</div>
          <Salon
            :id="salonChel.id"
            :name="`Кузьма в Челябинске`"
            :workers="salonChel.workers"
            :year="salonChel.year"
            :offices="salonChel.offices"
            :link="`${$route.fullPath}/kuzma/chelyabinsk`"
          />
        </div>

        <div class="block block--special">
          <Salon
            :name="`Кузьма в других городах`"
            :offices="kuzmaOthers"
            :link="`${$route.fullPath}/kuzma`"
            :special="true"
          />
        </div>

        <div class="block block--special">
          <Salon
            :name="`Контора в других городах`"
            :offices="kontoraOthers"
            :link="`${$route.fullPath}/kontora`"
            :special="true"
          />
        </div>
      </div>
      <div v-else class="news__loader">
        <Loader />
      </div>
    </div>

    <div class="news__side">
      <Note />
    </div>
  </section>
</template>

<script setup>
import Salon from '@/components/team/Salon'
import Worker from '@/components/team/Worker'
import Loader from '@/components/Loader'
import Note from '@/components/Note'
import Back from '@/components/Back'
import authorize from '@/mixins/authorize'
import isMobile from '@/mixins/isMobile'
import conjugate from '@/mixins/conjugate'
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()
const barbershops = ref([])
const salonChel = ref({ id: '', name: '', offices: '', year: '', workers: '' })
const kuzmaOthers = ref('')
const kontoraOthers = ref('')
const managers = ref('')

const getBarbershopsEkb = async () => {
  const data = await fetch(`https://barberkuzma.ru:1414/barbershops/?cities.id=1`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
  const response = await data.json()

  for (const barbershop of response) {
    processBarbershops(barbershop)
  }
}

const processBarbershops = async barbershop => {
  const dataWorkers = await fetch(
    `https://barberkuzma.ru:1414/staff?profileBarbershop=${barbershop.id}&profileCity=1`,
    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
  )
  const responseWorkers = await dataWorkers.json()

  const dataOffices = await fetch(`https://barberkuzma.ru:1414/adresses/?barbershop.id=${barbershop.id}&city.id=1`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
  const responseOffices = await dataOffices.json()

  barbershops.value.push({
    id: barbershop.id,
    name: barbershop.name,
    offices: responseOffices.length,
    year: Math.min(...responseOffices.map(item => item.foundationYear)),
    workers: responseWorkers.length,
    link: `${router.currentRoute.value.fullPath}/${barbershop.nameEng.toLowerCase()}/ekaterinburg`
  })
}

const getKuzmaChel = async () => {
  const dataWorkers = await fetch(`https://barberkuzma.ru:1414/staff?profileBarbershop=2&profileCity=4`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
  const responseWorkers = await dataWorkers.json()

  const dataOffices = await fetch(`https://barberkuzma.ru:1414/adresses/?barbershop.id=2&city.id=4`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
  const responseOffices = await dataOffices.json()

  salonChel.value.id = responseOffices.id
  salonChel.value.name = responseOffices.name
  salonChel.value.offices = responseOffices.length
  salonChel.value.year = Math.min(...responseOffices.map(item => item.foundationYear))
  salonChel.value.workers = responseWorkers.length
}

const getKuzmaOffices = async () => {
  const data = await fetch(
    `https://barberkuzma.ru:1414/adresses/?_where[0][barbershop.id]=2&_where[1][city.id_ne]=1&_where[2][city.id_ne]=4`,
    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
  )
  const response = await data.json()
  kuzmaOthers.value = response.length
}

const getKontoraOffices = async () => {
  const data = await fetch(`https://barberkuzma.ru:1414/adresses/?barbershop.id=1&city.id_ne=1`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
  const response = await data.json()
  kontoraOthers.value = response.length
}

const getManagers = async () => {
  const data = await fetch(`https://barberkuzma.ru:1414/users?positions.isManager=true`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
  const response = await data.json()
  managers.value = response
}

authorize()
getManagers()
getBarbershopsEkb()
getKuzmaChel()

getKuzmaOffices()
getKontoraOffices()
</script>

<style scoped>
.news {
  background: #1d232a;
  display: flex;
  align-items: flex-start;
  padding: 24px 50px 0 50px;
}
.news__content {
  position: relative;
  margin-right: 30px;
  flex: 1;
}
.news__loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news__side {
  width: 329px;
  position: sticky;
  top: 24px;
  height: fit-content;
}

.block {
  background: #343c42;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 30px;
}
.block--special {
  background: #2a3137;
  border-color: #ffe6a9;
}
.block:last-of-type {
  margin-bottom: 50px;
}
.block__header {
  display: flex;
  align-items: center;
  padding: 16px;
  letter-spacing: 0.02em;
  border-bottom: 1px solid #5d6368;
}
.block__header--noborder {
  border-bottom: none;
}
.block__back {
  display: flex;
  align-items: center;
  margin-right: 24px;
  color: rgba(255, 255, 255, 0.5);
  transition: 0.3s;
}
.block__back:hover {
  color: #ffda7e;
  transition: 0.3s;
}
.block__back:hover svg >>> path {
  stroke: #ffda7e;
  transition: 0.3s;
}
.block__back svg >>> path {
  transition: 0.3s;
}

@media only screen and (max-width: 600px) {
  .news {
    background: unset;
    display: unset;
    align-items: unset;
    padding: 0;
  }
  .news__content {
    margin-right: unset;
    flex: unset;
    padding: 16px 16px 88px 16px;
  }
  .news__side {
    display: none;
  }
  .news__loader {
    margin-top: 150px;
  }

  .block {
    margin-bottom: 16px;
  }
  .block:last-of-type {
    margin-bottom: 0;
  }
}
</style>
