import { ref } from 'vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const isOpenFilter = ref(false)
const courseName = ref(0)

export default () => {
  const toggleFilter = () => {
    if (isOpenFilter.value) {
      const filter = document.querySelector('.filterMobile')
      enableBodyScroll(filter)
      isOpenFilter.value = false
    } else {
      isOpenFilter.value = true
      setTimeout(() => {
        const filter = document.querySelector('.filterMobile')
        disableBodyScroll(filter)
      }, 0)
    }
  }

  return {
    courseName,
    isOpenFilter,
    toggleFilter
  }
}
