<template>
  <div class="selectbox">
    <div class="selectbox__label">{{ label }}</div>
    <select
      :id="name"
      class="selectbox__selectbox"
      :name="name"
      :value="value"
      @input="moveToSelected($event.target.value)"
    >
      <option value="0">{{ label }}</option>
      <option v-for="(option, index) in options" :key="index" :value="option.id">
        {{ option[keyName] }}
      </option>
    </select>
    <svg class="selectbox__icon" width="22" height="21" viewBox="0 0 22 21" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.8097 8.21181C15.0582 8.49421 15.0582 8.95208 14.8097 9.23449L11.4961 13L8.18248 9.23449C7.93396 8.95208 7.93396 8.49421 8.18248 8.2118C8.431 7.9294 8.83392 7.9294 9.08243 8.2118L11.4961 10.9546L13.9098 8.2118C14.1583 7.9294 14.5612 7.9294 14.8097 8.21181Z"
        fill="white"
      />
    </svg>

    <div v-if="selections.length > 0">
      <div v-for="selection in selections" :key="selection.id" class="selectbox__selection">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
          <path d="M6.51172 8.33301H16.1717" stroke="white" stroke-linecap="round" />
          <path d="M6.51172 11.333H16.1717" stroke="white" stroke-linecap="round" />
          <path d="M6.51172 14.333H16.1717" stroke="white" stroke-linecap="round" />
        </svg>

        <div class="selectbox__selectionText">{{ selection[keyName] }}</div>

        <svg
          class="selectbox__selectionIcon"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          @click="moveToOptions(selection)"
        >
          <circle opacity="0.2" cx="11.1719" cy="11.333" r="6" stroke="white" />
          <path d="M8.04102 11.333H14.3037" stroke="white" stroke-linecap="round" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, toRef } from 'vue'

const emit = defineEmits(['updateItems'])
const props = defineProps({
  label: {
    type: String,
    default () {
      return ''
    }
  },
  keyName: {
    type: String,
    default () {
      return ''
    }
  },
  name: {
    type: String,
    default () {
      return ''
    }
  },
  options: {
    type: Object,
    default () {
      return {}
    }
  },
  value: {
    type: Object,
    default () {
      return {}
    }
  }
})

const optionsRef = toRef(props, 'options')
const valueRef = toRef(props, 'value')

const options = ref(Object.values(Object.assign({}, optionsRef.value)))
const selections = ref(Object.values(Object.assign({}, valueRef.value)))
const value = ref('0')

selections.value.forEach(selection => {
  let index = options.value.map(option => option.id).indexOf(selection.id)

  options.value.splice(index, 1)
})

const moveToSelected = optionId => {
  const selectedElement = options.value.filter(option => option.id == optionId)[0]
  const index = options.value.indexOf(selectedElement)
  selections.value.push(selectedElement)
  options.value.splice(index, 1)
  value.value = '0'

  emit('updateItems', Object.values(selections.value))
}

const moveToOptions = selection => {
  const index = selections.value.indexOf(selection)

  options.value.push(selection)
  selections.value.splice(index, 1)

  emit('updateItems', Object.values(selections.value))
}
</script>

<style>
.selectbox {
  position: relative;
  margin: 0 8px 0 0;
}
.selectbox__label {
  font-size: 14px;
  line-height: 21px;
  opacity: 0.6;
  margin-bottom: 8px;
}
.selectbox__selectbox {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 4px;
  height: 37px;
  background: transparent;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  padding: 0 16px;
  transition: 0.3s;
}
.selectbox__selectbox:hover {
  border-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 4px rgba(255, 218, 126, 0.4);
  transition: 0.3s;
}
.selectbox__icon {
  position: absolute;
  right: 16px;
  top: 38px;
  pointer-events: none;
}
.selectbox__selection {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.selectbox__selection:first-of-type {
  margin-top: 16px;
}
.selectbox__selection:last-of-type {
  margin-bottom: 0;
}
.selectbox__selectionText {
  margin: 0 auto 0 8px;
}
.selectbox__selectionIcon {
  cursor: pointer;
  margin-right: 16px;
}
</style>
