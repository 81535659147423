<template>
  <div class="post">
    <div class="post__header">
      <div :class="['post__title', { 'post__title--nopadding': !props.isEditorUser }]">{{ props.post.title }}</div>
      <DotsSvg v-if="props.isEditorUser" class="post__dots" @click.stop="openDropdown" />

      <DropdownMobile
        v-if="isMobile && isOpenDropdown"
        ref="dropdownMobileRef"
        @edit="emit('toggleEditing')"
        @remove="remove"
        @close="closeDropdown"
      />
      <Dropdown v-if="!isMobile && isOpenDropdown" @edit="emit('toggleEditing')" @remove="remove" />
    </div>
    <div class="post__stamp">
      <div class="post__time">
        {{ postTime }}
      </div>
      <div class="post__date">
        {{ postDate }}
      </div>
    </div>

    <div class="post__text" v-html="sanitizedContent"></div>

    <div v-show="!isFull" class="post__more" @click="openContent">
      Показать полностью...
    </div>

    <div class="post__imgbox">
      <img
        v-if="post.previewImage"
        v-lazy="`https://barberkuzma.ru:1414${post.previewImage.url}`"
        class="post__image"
      />
      <img class="post__cover" src="@/assets/img/settings/coverIcon.svg" />
    </div>
  </div>
</template>

<script setup>
// Svg
import DotsSvg from '@/components/svg/DotsSvg'

// Components
import PostEdit from '@/components/news/PostEdit'
import Dropdown from '@/components/Dropdown'
import DropdownMobile from '@/components/DropdownMobile'

// Composables
import isMobile from '@/mixins/isMobile'
import useAlert from '@/mixins/useAlert'
import usePost from '@/mixins/usePost'

import DOMPurify from 'dompurify'
import { ref, computed } from 'vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const emit = defineEmits(['toggleEditing'])
const props = defineProps({
  post: {
    type: Object,
    default () {
      return {}
    }
  },
  isEditorUser: {
    type: Boolean,
    default () {
      return false
    }
  }
})

const dropdownMobileRef = ref(null)
const isFull = ref(false)
const isOpenDropdown = ref(false)
const { alertTitle, alertTitleSuccess, alertSubtitle, alertSubtitleSuccess, toggleAlert, task } = useAlert()
const { postId, removePost } = usePost()

const openContent = () => {
  isFull.value = true
}

const sanitizedContent = computed(() => {
  return isFull.value ? DOMPurify.sanitize(props.post.content) : DOMPurify.sanitize(props.post.previewText)
})

const postDate = computed(() => {
  return new Date(props.post.date).toLocaleDateString()
})

const postTime = computed(() => {
  let postTime = new Date(props.post.published_at)
  return postTime.toLocaleTimeString('ru', {
    hour: '2-digit',
    minute: '2-digit'
  })
})

const clickOutside = e => {
  if (e.target.parentNode && !e.target.parentNode.matches('.dropdown')) closeDropdown()
}

const openDropdown = () => {
  isOpenDropdown.value = true
  setTimeout(() => {
    if (isMobile.value) disableBodyScroll(dropdownMobileRef.value.$el)
  }, 0)
}

const closeDropdown = () => {
  if (isMobile.value) enableBodyScroll(dropdownMobileRef.value.$el)
  isOpenDropdown.value = false
}

const remove = () => {
  closeDropdown()
  postId.value = props.post.id

  alertTitle.value = 'Удалить запись?'
  alertTitleSuccess.value = 'Запись удалена'
  alertSubtitle.value = props.post.title
  alertSubtitleSuccess.value = 'Запись удалена'
  task.value = removePost
  toggleAlert()
}

if (!isMobile.value) window.addEventListener('click', clickOutside)
</script>

<style>
.post {
  position: relative;
  background: #343c42;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 30px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.post__header {
  color: #ffda7e;
  font-size: 16px;
  line-height: 27px;
  margin-bottom: 9px;
  display: flex;
  align-items: center;
}
.post__title {
  flex: 1;
  padding-right: 20px;
}
.post__title--nopadding {
  padding: 0;
}
.post__dots {
  display: block;
}
.post__stamp {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.post__time {
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
  margin-right: 8px;
}
.post__date {
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.3;
}
.post__text {
  color: #ffffff;
  margin-bottom: 8px;
}
.post__text p:first-of-type {
  margin-top: 0;
}
.post__text p:last-of-type {
  margin-bottom: 0;
}
.post__more {
  color: #ffda7e;
  margin-bottom: 16px;
  cursor: pointer;
}
.post__imgbox {
  position: relative;
  width: 100%;
  height: 250px;
  border-radius: 8px;
  overflow: hidden;
  background: #1d232a;
}
.post__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  position: relative;
}
.post__cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .post {
    margin-bottom: 16px;
  }
  .post__title {
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 9px;
  }
  .post__cover {
    width: 80px;
  }
}
</style>
