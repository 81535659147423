import { ref } from 'vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const isOpenAlert = ref(false)
const alertTitle = ref(null)
const alertTitleSuccess = ref(null)
const alertSubtitle = ref(null)
const alertSubtitleSuccess = ref(null)
const task = ref(null)

export default () => {
  const toggleAlert = () => {
    if (isOpenAlert.value) {
      const alert = document.querySelector('.alert')
      enableBodyScroll(alert)
      isOpenAlert.value = false
    } else {
      isOpenAlert.value = true
      setTimeout(() => {
        const alert = document.querySelector('.alert')
        disableBodyScroll(alert)
      }, 0)
    }
  }

  return {
    isOpenAlert,
    alertTitle,
    alertTitleSuccess,
    alertSubtitle,
    alertSubtitleSuccess,
    task,
    toggleAlert
  }
}
