<template>
  <svg width="37" height="36" viewBox="0 0 37 36" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.8711 36C28.8122 36 36.8711 27.9411 36.8711 18C36.8711 8.05887 28.8122 0 18.8711 0C8.92997 0 0.871094 8.05887 0.871094 18C0.871094 27.9411 8.92997 36 18.8711 36ZM10.9464 8.50208C12.972 6.82468 15.4009 5.84614 18.0127 5.6553C18.4573 5.62282 19.6937 5.64382 20.1326 5.69134C22.657 5.96455 24.9334 6.93322 26.8582 8.55328C27.1678 8.81383 27.8605 9.4952 28.144 9.818C30.7053 12.7347 31.7518 16.6247 30.9956 20.4175C30.6193 22.3048 29.8151 24.061 28.6273 25.5889C27.1606 27.4755 25.1293 28.9244 22.8708 29.695C18.6632 31.1307 14.0444 30.2101 10.694 27.268C10.3748 26.9876 9.69004 26.2914 9.44199 25.9949C7.87079 24.1168 6.90528 21.9296 6.60675 19.5721C6.17144 16.1343 7.15391 12.7651 9.37746 10.0704C9.68252 9.70065 10.5687 8.81484 10.9464 8.50208ZM24.9777 13.9215C25.0321 13.5812 24.9273 13.292 24.7004 13.1556C24.5857 13.0866 24.5396 13.0737 24.4114 13.0745C24.3275 13.075 24.2021 13.0963 24.1327 13.1219C24.0633 13.1474 22.6039 13.7102 20.8897 14.3725C19.1755 15.0348 16.5067 16.065 14.9591 16.6619C13.4114 17.2589 12.077 17.7814 11.9937 17.8231C11.5827 18.0289 11.4473 18.2775 11.6318 18.4876C11.6757 18.5376 11.7637 18.6045 11.8274 18.6362C11.8911 18.668 12.6474 18.9127 13.5079 19.1799C14.3685 19.4472 15.0754 19.6684 15.0788 19.6714C15.0822 19.6745 15.3473 20.5383 15.668 21.5909C15.9886 22.6435 16.2634 23.5248 16.2787 23.5493C16.3235 23.621 16.4131 23.6478 16.6083 23.6478C16.6589 23.6478 16.7012 23.648 16.7428 23.6424C16.9661 23.6124 17.1668 23.4147 18.4933 22.1164L18.7088 21.9054L18.9601 22.0922C20.5911 23.3047 21.8003 24.1907 21.8786 24.2306C22.0272 24.3065 22.2713 24.3613 22.3743 24.342C22.5872 24.302 22.7284 24.1805 22.8305 23.9493C22.8578 23.8876 22.9541 23.4907 23.0446 23.0674C23.7013 19.9946 24.9553 14.0615 24.9777 13.9215Z"
      fill="white"
    />
  </svg>
</template>
