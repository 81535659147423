<template>
  <svg width="36" height="36" viewBox="0 0 37 36" fill="none">
    <path
      opacity="0.6"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.8817 11.0402C20.4811 11.1293 20.5543 11.0608 17.16 14.5189C15.155 16.5617 13.9412 17.8223 13.8895 17.9155C13.7404 18.1846 13.7068 18.3798 13.5599 19.8269C13.3833 21.566 13.3806 21.6712 13.5076 21.8752C13.6162 22.0499 13.873 22.1996 14.064 22.1996C14.339 22.1996 17.0663 21.9298 17.239 21.8855C17.3424 21.859 17.4951 21.798 17.5785 21.7498C17.6773 21.6927 18.8528 20.5173 20.9647 18.3637C24.5154 14.743 24.3461 14.9348 24.401 14.4696C24.4355 14.178 24.3964 13.9251 24.2798 13.6844C24.1972 13.514 24.0122 13.3104 23.0733 12.3564C21.8719 11.1356 21.8222 11.0959 21.4093 11.0269C21.1777 10.9883 21.1073 10.99 20.8817 11.0402ZM22.1204 13.3289L23.0516 14.2786L22.366 14.9634L21.6805 15.6481L20.7639 14.7141C20.2597 14.2005 19.8473 13.7721 19.8473 13.7622C19.8473 13.7355 21.1421 12.3792 21.1675 12.3792C21.1794 12.3792 21.6083 12.8065 22.1204 13.3289ZM13.7807 23.6997C13.5555 23.8141 13.4385 23.9902 13.4206 24.2419C13.3973 24.5707 13.5274 24.8196 13.7848 24.9387C13.9097 24.9966 14.134 24.9993 18.9028 25C23.3323 25.0006 23.9036 24.995 24.0099 24.9497C24.5006 24.7406 24.5629 24.0506 24.1197 23.7334L23.9795 23.6331L18.9627 23.6244L13.9458 23.6158L13.7807 23.6997Z"
      fill="white"
    />
  </svg>
</template>
