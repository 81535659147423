<template>
  <section class="settings">
    <div class="settings__content">
      <Subtitle v-if="isMobile" @click="toggleFilter" />

      <div v-if="store.state.user">
        <Profile ref="profile" :user="store.state.user" />
        <Socials ref="socials" />
        <About ref="about" :user="store.state.user" />
        <Interests ref="interests" :user="store.state.user" />
        <Education ref="education" :user="store.state.user" />
        <Career ref="career" :user="store.state.user" />
        <Safety ref="safety" />
      </div>

      <div v-else class="settings__loader">
        <Loader />
      </div>
    </div>

    <FilterMobile v-if="isMobile" @update="update" />

    <div v-else class="settings__side">
      <div class="filter">
        <div :class="['filter__item', { 'filter__item--active': filter == 'profile' }]" @click="update('profile')">
          Профиль
        </div>
        <div :class="['filter__item', { 'filter__item--active': filter == 'socials' }]" @click="update('socials')">
          Социальные сети
        </div>
        <div :class="['filter__item', { 'filter__item--active': filter == 'about' }]" @click="update('about')">
          О себе
        </div>
        <div :class="['filter__item', { 'filter__item--active': filter == 'interests' }]" @click="update('interests')">
          Интересы
        </div>
        <div :class="['filter__item', { 'filter__item--active': filter == 'education' }]" @click="update('education')">
          Образование
        </div>
        <div :class="['filter__item', { 'filter__item--active': filter == 'career' }]" @click="update('career')">
          Карьера
        </div>
        <div :class="['filter__item', { 'filter__item--active': filter == 'safety' }]" @click="update('safety')">
          Безопасность
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
// Components
import Profile from '@/components/settings/Profile'
import Socials from '@/components/settings/Socials'
import About from '@/components/settings/About'
import Interests from '@/components/settings/Interests'
import Education from '@/components/settings/Education'
import Career from '@/components/settings/Career'
import Safety from '@/components/settings/Safety'
import Loader from '@/components/Loader'
import Subtitle from '@/components/Subtitle'
import FilterMobile from '@/components/settings/FilterMobile'

// Helpers
import authorize from '@/mixins/authorize'
import isMobile from '@/mixins/isMobile'
import useFilter from '@/mixins/useFilter'

import { ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const filter = ref('profile')
let { toggleFilter } = useFilter()

const profile = ref(null)
const socials = ref(null)
const about = ref(null)
const interests = ref(null)
const education = ref(null)
const career = ref(null)
const safety = ref(null)

const update = state => {
  let ref = null

  switch (state) {
    case 'profile':
      ref = profile
      break
    case 'socials':
      ref = socials
      break
    case 'about':
      ref = about
      break
    case 'interests':
      ref = interests
      break
    case 'education':
      ref = education
      break
    case 'career':
      ref = career
      break
    case 'safety':
      ref = safety
      break
    default:
  }

  let position = ref.value.$el.getBoundingClientRect().top + window.pageYOffset - 24
  window.scrollTo({ top: position, left: 0, behavior: 'smooth' })
}

const updateSideMenu = () => {
  let refs = [
    { name: 'profile', obj: profile },
    { name: 'socials', obj: socials },
    { name: 'about', obj: about },
    { name: 'interests', obj: interests },
    { name: 'education', obj: education },
    { name: 'career', obj: career },
    { name: 'safety', obj: safety }
  ]

  refs.forEach(ref => {
    let observer
    let target

    if (ref.obj.value.$el.querySelector('.profile__header')) {
      target = ref.obj.value.$el.querySelector('.profile__header')
    } else {
      target = ref.obj.value.$el.querySelector('.profile__profile')
    }
    let handler = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) filter.value = ref.name
      })
    }
    observer = new IntersectionObserver(handler, {
      rootMargin: '0px 0px -90% 0px'
    })
    observer.observe(target)
  })
}

authorize()
setTimeout(() => updateSideMenu(), 1000)
</script>

<style>
.button {
  display: block;
  color: #15191c;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  min-height: 42px;
  min-width: 113px;
}
.button--filled {
  color: #15191c;
  background: #ffda7e;
  transition: 0.3s;
}
.button--filled:hover {
  background: #ffe6a9;
  box-shadow: 0px 0px 14px rgba(255, 218, 126, 0.4);
  transition: 0.3s;
}
.button--plain {
  color: #ffda7e;
  border: 1px solid #ffda7e;
  transition: 0.3s;
}
.button--plain:hover {
  color: #ffe6a9;
  box-shadow: 0px 0px 14px rgba(255, 218, 126, 0.4);
  border-color: #ffe6a9;
  transition: 0.3s;
}
.button--success {
  pointer-events: none;
  border-color: #bff6ac !important;
  background: #bff6ac !important;
  color: #15191c;
  transition: 0.3s;
}
.button--disabled {
  pointer-events: none;
}

.button__success {
  display: block;
  margin: auto;
}
.button__loader {
  display: block;
  margin: auto;
  animation: rotate 2s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(-360deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.settings {
  background: #1d232a;
  display: flex;
  align-items: flex-start;
  padding: 24px 50px 0 50px;
}
.settings__content {
  position: relative;
  margin-right: 30px;
  flex: 1;
  padding-bottom: 463px;
}
.settings__loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.settings__side {
  width: 329px;
  position: sticky;
  top: 24px;
  height: fit-content;
}

.filter {
  background: #2a3137;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  overflow: hidden;
}
.filter__item {
  position: relative;
  padding: 28px 24px;
  font-size: 16px;
  line-height: 27px;
  color: rgba(255, 255, 255, 0.8);
  transition: 0.3s;
}
.filter__item--active {
  pointer-events: none;
  background: #343c42;
  color: white;
  transition: 0.3s;
}
.filter__item--active::after {
  content: '';
  position: absolute;
  right: 15px;
  top: 16px;
  height: 48px;
  width: 4px;
  background: #e0b184;
}
.filter__item:hover {
  cursor: pointer;
  background: #343c42;
  transition: 0.3s;
}

.profile {
  background: #343c42;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  margin-bottom: 24px;
}
.profile__header {
  padding: 16px 20px;
  border-bottom: 1px solid #5d6368;
}
.profile__body {
  padding: 22px 40px;
}
.profile__profile {
  position: relative;
  display: flex;
  width: 100%;
  margin: 0 0 24px 0;
}
.profile__content {
  flex: 1;
  position: relative;
}
.profile__imgbox {
  position: relative;
  width: 154px;
  height: 154px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1d232a;
  border-radius: 100%;
  margin-right: 24px;
  cursor: pointer;
  overflow: hidden;
}
.profile__imgbox:hover .profile__cover {
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
}
.profile__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}
.profile__positions {
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  opacity: 0.6;
  margin-bottom: 16px;
}
.profile__position {
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  margin-bottom: 6px;
}
.profile__position:last-of-type {
  margin-bottom: 0;
}
.profile__person {
  position: absolute;
  margin-bottom: 20px;
}
.profile__cover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 218, 126, 0) 39.23%, #ffda7e 100%);
  box-shadow: 0px 8px 60px rgba(255, 218, 126, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  color: #15191c;
  text-align: center;
  padding-bottom: 9px;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
  transition: 0.3s;
}
.profile__files {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  opacity: 0;
}
.profile__name {
  font-weight: 600;
  margin-bottom: 16px;
  overflow: hidden;
  max-height: 72px;
}
.profile__label {
  font-size: 14px;
  line-height: 21px;
  opacity: 0.6;
  margin-bottom: 8px;
}
.profile__statusbox {
  position: relative;
  margin-bottom: 60px;
}
.profile__status {
  position: absolute;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 24px;
  background: transparent;
  width: 100%;
  color: #71777b;
  border-radius: 4px;
  transition: 0.3s;
}
.profile__status:hover {
  cursor: pointer;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 4px rgba(255, 218, 126, 0.4);
  padding: 8px 16px;
  transition: 0.3s;
}
.profile__status:focus {
  border: 1px solid #ffda7e;
  color: white;
  padding: 8px 16px;
  box-shadow: none;
  transition: 0.3s;
}
.profile__inputs {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px 32px 0;
}
.profile__inputs:last-of-type {
  margin-bottom: 0;
}
.profile__inputbox {
  position: relative;
  margin: 0 8px 24px 0;
}
.profile__inputbox:last-of-type {
  margin-bottom: 0;
}
.profile__inputbox--full {
  width: 100%;
}
.profile__inputbox--half {
  width: calc(50% - 8px);
}
.profile__input {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 4px;
  height: 37px;
  background: transparent;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  padding: 0 16px;
  transition: 0.3s;
}
.profile__input:hover {
  border-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 4px rgba(255, 218, 126, 0.4);
  transition: 0.3s;
}
.profile__input--input {
  transition: 0.3s;
}
.profile__input--input:focus {
  border-color: #ffda7e;
  box-shadow: none;
  transition: 0.3s;
}
.profile__icon {
  position: absolute;
  right: 16px;
  bottom: 8px;
}
.profile__button {
  margin: 32px 0 0 auto;
}
.profile__button:last-of-type {
  margin-bottom: 0;
}
.profile__more {
  width: 100%;
  padding: 32px 0;
  border-bottom: 1px solid #71767b;
  margin: 0 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.profile__item {
  margin-bottom: 24px;
}
.profile__item--full {
  width: 100%;
}
.profile__item--half {
  width: calc(50% - 8px);
}

@media only screen and (max-width: 600px) {
  .button {
    min-width: unset;
  }
  .button--plain {
    width: 100%;
  }
  .button--filled:hover {
    background: #ffda7e;
    box-shadow: none;
  }
  .button--filled {
    width: 100%;
  }
  .profile {
    margin-bottom: 16px;
  }
  .profile__profile {
    flex-direction: column;
  }
  .profile__body {
    padding: 16px;
  }
  .profile__imgbox {
    margin: auto;
  }
  .profile__content {
    margin-top: 24px;
  }
  .profile__cover {
    visibility: visible;
    opacity: 0 !important;
  }
  .profile__label {
    display: none;
  }
  .profile__statusbox {
    margin-bottom: 16px;
  }
  .profile__status {
    position: relative;
    top: unset;
    padding: 8px 16px;
    border: 1px solid transparent;
    margin-bottom: 0;
  }
  .settings {
    background: unset;
    display: unset;
    align-items: unset;
    padding: 0;
  }
  .settings__side {
    display: none;
  }
  .settings__content {
    margin-right: unset;
    flex: unset;
    padding: 16px 16px 70px 16px;
  }
  .settings__loader {
    top: 160px;
  }
}
</style>
