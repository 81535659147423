<template>
  <div>
    <section class="news">
      <div class="news__content">
        <div v-if="user" class="prof">
          <Subtitle v-if="isMobile" @click="toggleFilter" />
          <Back :to="`/team`" />

          <Profile ref="profile" :user="user" />
          <Work ref="work" :user="user" />
          <Courses
            ref="course"
            :courses="courses"
            :courses-inner="coursesInner"
            :courses-kontora="coursesKontora"
            :courses-manufactura="coursesManufactura"
          />
          <Socials ref="socials" :user="user" />
          <About ref="about" :user="user" />
          <Interests ref="interests" :user="user" />
          <Education ref="education" :user="user" />
          <Career ref="career" :user="user" />
        </div>

        <div v-else class="news__loader">
          <Loader />
        </div>
      </div>

      <FilterMobile v-if="isMobile" :user="user" :courses="courses" @update="update" />

      <div v-else class="news__side">
        <Filter v-if="!isMobile" ref="filter" :user="user" :courses="courses" @update="update" />
      </div>
    </section>
  </div>
</template>

<script setup>
// Components
import Profile from '@/components/profile/Profile'
import Work from '@/components/profile/Work'
import Courses from '@/components/profile/Courses'
import Socials from '@/components/profile/Socials'
import About from '@/components/profile/About'
import Interests from '@/components/profile/Interests'
import Education from '@/components/profile/Education'
import Career from '@/components/profile/Career'
import Back from '@/components/Back'
import Loader from '@/components/Loader'
import Subtitle from '@/components/Subtitle'
import Filter from '@/components/profile/Filter'
import FilterMobile from '@/components/profile/FilterMobile'

// Helpers
import authorize from '@/mixins/authorize'
import isMobile from '@/mixins/isMobile'
import useFilter from '@/mixins/useFilter'

import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()
const user = ref(null)
const courses = ref([])
const coursesInner = ref(0)
const coursesKontora = ref(0)
const coursesManufactura = ref(0)
let { toggleFilter } = useFilter()

const filter = ref(null)
const profile = ref(null)
const work = ref(null)
const course = ref(null)
const socials = ref(null)
const about = ref(null)
const interests = ref(null)
const education = ref(null)
const career = ref(null)

const update = state => {
  let ref = null

  switch (state) {
    case 'profile':
      ref = profile
      break
    case 'work':
      ref = work
      break
    case 'course':
      ref = course
      break
    case 'socials':
      ref = socials
      break
    case 'about':
      ref = about
      break
    case 'interests':
      ref = interests
      break
    case 'education':
      ref = education
      break
    case 'career':
      ref = career
      break
    default:
  }

  let position = ref.value.$el.getBoundingClientRect().top + window.pageYOffset - 24
  window.scrollTo({ top: position, left: 0, behavior: 'smooth' })
}

const updateSideMenu = () => {
  let refs = [
    { name: 'profile', obj: profile },
    { name: 'work', obj: work },
    { name: 'course', obj: course },
    { name: 'socials', obj: socials },
    { name: 'about', obj: about },
    { name: 'interests', obj: interests },
    { name: 'education', obj: education },
    { name: 'career', obj: career }
  ]

  refs.forEach(ref => {
    if (ref.obj.value != null) {
      let observer
      let target

      if (ref.obj.value.querySelector('.prof__title')) {
        target = ref.obj.value.querySelector('.prof__title')
      } else {
        target = ref.obj.value.querySelector('.prof__imgbox')
      }
      let handler = entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) filter.value.filter = ref.name
        })
      }
      observer = new IntersectionObserver(handler, {
        rootMargin: '0px 0px -90% 0px'
      })
      observer.observe(target)
    }
  })
}

const getUserData = async () => {
  const data = await fetch(`https://barberkuzma.ru:1414/users/${router.currentRoute.value.params.id}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
  const response = await data.json()
  user.value = response
}

const getCoursesData = async () => {
  const data = await fetch(
    `https://barberkuzma.ru:1414/course-records?users_permissions_user=${router.currentRoute.value.params.id}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }
  )
  const response = await data.json()
  courses.value = response
  coursesInner.value = courses.value.filter(course => course.course.category == 'inner').length
  coursesKontora.value = courses.value.filter(course => course.course.category == 'Kontora').length
  coursesManufactura.value = courses.value.filter(course => course.course.category == 'manufactura').length
}

authorize()
getUserData()
getCoursesData()
if (!isMobile.value) setTimeout(() => updateSideMenu(), 1000)
</script>

<style>
.prof {
  position: relative;
  padding-bottom: 600px;
}
.prof__prof {
  margin-bottom: 4px;
}
.prof__imgbox {
  position: relative;
  width: 100px;
  height: 100px;
  margin-right: 37px;
  border: 1px solid #71767b;
  border-radius: 100%;
}
.prof__img {
  width: 100%;
  height: 100%;
}
.prof__icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.prof__netname {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 8px;
}
.prof__name {
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 18px;
}
.prof__position {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  opacity: 0.6;
}
.prof__statuslabel {
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  opacity: 0.6;
  margin-bottom: 4px;
}
.prof__status {
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}
.prof__block {
  background: #343c42;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  margin-bottom: 30px;
}
.prof__title {
  display: flex;
  align-items: center;
  padding: 13px 16px;
  color: #ffffff;
  border-bottom: 1px solid #5d6368;
}
.prof__content {
  padding: 13px 24px 24px 24px;
}
.prof__content--first {
  display: flex;
  align-items: center;
}
.prof__text {
  letter-spacing: 0.02em;
}
.prof__item {
  margin-bottom: 24px;
}
.prof__item--last {
  margin-bottom: 24px !important;
}
.prof__item:last-of-type {
  margin-bottom: 0;
}
.prof__val {
  font-size: 14px;
  line-height: 24px;
  opacity: 0.6;
}
.prof__socials {
  display: flex;
  align-items: center;
}
.prof__social {
  display: flex;
  margin-right: 18px;
}
.prof__social:deep(path) {
  transition: 0.3s;
}
.prof__social:hover :deep(path) {
  fill: #ffe6a9;
  transition: 0.3s;
}
.prof__course {
  display: flex;
  align-items: center;
  justify-content: space-between;
  letter-spacing: 0.02em;
  margin-bottom: 16px;
}
.prof__course:last-of-type {
  margin-bottom: 0;
}
.prof__adress {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffda7e;
  border: 1px solid #ffda7e;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  color: #15191c;
  height: 56px;
  margin-bottom: 24px;
}

.news {
  background: #1d232a;
  display: flex;
  align-items: flex-start;
  padding: 24px 50px 0 50px;
}
.news__content {
  position: relative;
  margin-right: 30px;
  flex: 1;
}
.news__loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news__side {
  width: 329px;
  position: sticky;
  top: 24px;
  height: fit-content;
}

@media only screen and (max-width: 600px) {
  .prof {
    padding-bottom: 0;
  }
  .prof__block {
    margin-bottom: 16px;
  }
  .prof__block:last-of-type {
    margin-bottom: 0;
  }
  .prof__imgbox {
    width: 154px;
    height: 154px;
    margin: auto auto 24px auto;
  }
  .prof__social:hover :deep(path) {
    fill: white;
  }
  .prof__content {
    padding: 16px;
  }
  .prof__content--first {
    display: block;
  }

  .news {
    background: unset;
    display: unset;
    align-items: unset;
    padding: 0;
  }
  .news__content {
    margin-right: unset;
    flex: unset;
    padding: 16px 16px 88px 16px;
  }
  .news__side {
    display: none;
  }
  .news__loader {
    margin-top: 150px;
  }
}
</style>
