import { ref } from 'vue'
import fetchPosts from '@/mixins/fetchPosts'
import fetchCourses from '@/mixins/fetchCourses'

const events = ref(null)
const eventsLoaded = ref(null)

export default () => {
  let { posts, getPosts } = fetchPosts()
  let { courses, getCourses } = fetchCourses()

  const getRecords = async () => {
    for (const [index, course] of courses.value.entries()) {
      const data = await fetch(`https://barberkuzma.ru:1414/course-records/?course.id=${course.id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      })
      const response = await data.json()

      const index = Object.values(events.value)
        .map(event => event.title || event.course)
        .indexOf(course.course)

      events.value[index]['records'] = response
    }
  }

  const fetchAll = async () => {
    await Promise.all([getPosts(), getCourses()])

    let processedEvents = [...posts.value, ...courses.value].sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    )
    events.value = processedEvents
    eventsLoaded.value = processedEvents

    getRecords()
  }

  return {
    events,
    eventsLoaded,
    fetchAll
  }
}
