import { createStore } from 'vuex'

export default createStore({
  state: {
    user: '',
    state: 'settings'
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    },
    setState (state, newState) {
      state.state = newState
    }
  },
  actions: {},
  modules: {}
})
