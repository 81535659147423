<template>
  <div class="course">
    <div class="course__header">
      <Label :category="course.category" />
      <DotsSvg v-if="props.isEditorUser" class="post__dots" @click.stop="openDropdown" />
      <div v-else class="course__date">{{ date }}</div>

      <DropdownMobile
        v-if="isMobile && isOpenDropdown"
        ref="dropdownMobileRef"
        @edit="emit('toggleEditing')"
        @remove="remove"
        @close="closeDropdown"
      />
      <Dropdown v-if="!isMobile && isOpenDropdown" @edit="emit('toggleEditing')" @remove="remove" />
    </div>

    <div class="course__content" :class="{ 'course__content--unfolded': !folded }">
      <div class="course__description">
        <div class="course__label">{{ course.course }}</div>
        <div v-html="DOMPurify.sanitize(course.description)"></div>

        <div v-if="course.expectation">
          <div class="course__label">Что вас ожидает на курсе</div>
          <div v-html="DOMPurify.sanitize(course.expectation)"></div>
        </div>

        <div v-if="course.program">
          <div class="course__label">Программа курса</div>
          <div v-html="DOMPurify.sanitize(course.program)"></div>
        </div>

        <div v-if="course.outcome">
          <div class="course__label">Что вы получите</div>
          <div v-html="DOMPurify.sanitize(course.outcome)"></div>
        </div>
      </div>

      <div class="course__grid">
        <div class="course__item">
          <div class="course__subtitle">Продолжительность курса</div>
          <div v-if="course.durationMonths">
            {{ course.durationMonths }}
            {{ conjugate(course.durationMonths, 'месяц', 'месяца', 'месяцев') }}
          </div>
          <div v-if="course.durationDays">
            {{ course.durationDays }}
            {{ conjugate(course.durationDays, 'день', 'дня', 'дней') }}
          </div>
        </div>
        <div class="course__item">
          <div class="course__subtitle">Адрес прохождения</div>
          <div>Екатеринбург, Сибирский тракт 8н</div>
        </div>
        <div v-if="course.priceFull || course.priceStart" class="course__item">
          <div class="course__subtitle">Разовый платеж</div>
          <div v-if="course.priceFull" class="course__pricebox">
            <div class="course__key">{{ course.priceFull.toLocaleString() }} ₽</div>
            <div class="course__val">C отработкой</div>
          </div>
          <div v-if="course.priceStart" class="course__pricebox">
            <div class="course__key">{{ course.priceStart.toLocaleString() }} ₽</div>
            <div class="course__val">Без отработкой</div>
          </div>
        </div>
        <div v-if="course.priceFull || course.priceStart" class="course__item">
          <div class="course__subtitle">Расрочка</div>
          <div v-if="course.priceFull" class="course__pricebox">
            <div class="course__key">
              {{ Math.round(course.priceFull / 6).toLocaleString() }} ₽/<span class="course__x">х6</span>
            </div>
            <div class="course__val">C отработкой</div>
          </div>
          <div v-if="course.priceStart" class="course__pricebox">
            <div class="course__key">
              {{ Math.round(course.priceStart / 6).toLocaleString() }} ₽/<span class="course__x">х6</span>
            </div>
            <div class="course__val">Без отработкой</div>
          </div>
        </div>
      </div>
      <div v-if="course.records">
        <button v-if="isEnrolled" class="button button--filled button--disabled">
          Заявка отправлена
        </button>
        <button v-else class="button button--plain" @click="subscribe">
          Записаться на обучение
        </button>
      </div>
    </div>

    <button class="course__folder" @click="toggleFold">
      <span v-if="folded">Развернуть</span>
      <span v-else>Свернуть</span>
    </button>

    <div
      v-if="course.records"
      class="course__footer"
      :class="{ 'course__footer--disabled': !avatars.length }"
      @click="showModalRecords"
    >
      <div v-if="avatars.length" class="course__avatars">
        <div
          v-for="(record, index) in avatars"
          :key="record.id"
          class="course__avatar"
          :class="`course__avatar--${index}`"
        >
          <img
            v-if="record.users_permissions_user.profile.profileImg"
            v-lazy="
              `https://barberkuzma.ru:1414${record.users_permissions_user.profile.profileImg.formats.thumbnail.url}`
            "
            class="course__img"
          />

          <AvatarSvg class="course__icon" />
        </div>
      </div>
      <div v-else class="course__avatars">
        <div class="course__avatar">
          <AvatarSvg class="course__icon" />
        </div>
      </div>

      <div v-if="course.records" class="course__count">
        <div v-if="course.records.length > 0">
          {{ course.records.length }}
          {{ conjugate(course.records.length, 'голова', 'головы', 'голов') }}
        </div>
        <div v-else>
          Пока ни одно головы, стань первым
        </div>
      </div>
      <TriangleSvg />
    </div>
  </div>
</template>

<script setup>
// Svg
import DotsSvg from '@/components/svg/DotsSvg'
import AvatarSvg from '@/components/svg/AvatarSvg'
import TriangleSvg from '@/components/svg/education/TriangleSvg'

// Components
import Label from '@/components/education/Label'
import Dropdown from '@/components/Dropdown'
import DropdownMobile from '@/components/DropdownMobile'

// Composables
import isMobile from '@/mixins/isMobile'
import conjugate from '@/mixins/conjugate'
import useModalRecords from '@/mixins/useModalRecords'
import useAlert from '@/mixins/useAlert'
import useCourse from '@/mixins/useCourse'

import DOMPurify from 'dompurify'
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const emit = defineEmits(['toggleEditing'])
const props = defineProps({
  course: {
    type: Object,
    default () {
      return {}
    }
  },
  isEditorUser: {
    type: Boolean,
    default () {
      return false
    }
  }
})

const dropdownMobileRef = ref(null)
const store = useStore()
const folded = ref(true)
const isOpenDropdown = ref(false)
const { courseName, records, toggleModal } = useModalRecords()
const { alertTitle, alertTitleSuccess, alertSubtitle, alertSubtitleSuccess, toggleAlert, task } = useAlert()
const { courseId, removeCourse, createRecord } = useCourse()

const date = computed(() => {
  return new Date(props.course.date).toLocaleDateString()
})
const avatars = computed(() => {
  return props.course.records.slice(0, 3)
})
const isEnrolled = computed(() => {
  if (props.course.records.length == 0) {
    return false
  } else {
    return props.course.records.filter(record => record.users_permissions_user.id == store.state.user.id).length == 1
  }
})

const toggleFold = () => {
  folded.value ? (folded.value = false) : (folded.value = true)
}

const showModalRecords = () => {
  courseId.value = props.course.id
  courseName.value = props.course.course
  records.value = props.course.records
  toggleModal()
}

const subscribe = () => {
  courseId.value = props.course.id

  alertTitle.value = 'Записаться на курс?'
  alertTitleSuccess.value = 'Успешная запись'
  alertSubtitle.value = props.course.course
  alertSubtitleSuccess.value = 'Вы записаны на курс'
  task.value = createRecord
  toggleAlert()
}

const clickOutside = e => {
  if (e.target.parentNode && !e.target.parentNode.matches('.dropdown')) closeDropdown()
}

const openDropdown = () => {
  isOpenDropdown.value = true
  setTimeout(() => {
    if (isMobile.value) disableBodyScroll(dropdownMobileRef.value.$el)
  }, 0)
}

const closeDropdown = () => {
  if (isMobile.value) enableBodyScroll(dropdownMobileRef.value.$el)
  isOpenDropdown.value = false
}

const remove = () => {
  closeDropdown()
  courseId.value = props.course.id

  alertTitle.value = 'Удалить курс?'
  alertTitleSuccess.value = 'Курс удален'
  alertSubtitle.value = props.course.course
  alertSubtitleSuccess.value = 'Курс удален'
  task.value = removeCourse
  toggleAlert()
}

if (!isMobile.value) window.addEventListener('click', clickOutside)
</script>

<style scoped>
.course {
  position: relative;
  color: white;
  background: #343c42;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 30px;
}
.course__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.course__date {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #c2c4c6;
}
.course__label {
  color: white;
  font-weight: 700;
  margin-bottom: 16px;
}
.course__content {
  max-height: 88px;
  overflow: hidden;
}
.course__content--unfolded {
  max-height: 100%;
}
.course__description {
  color: rgba(255, 255, 255, 0.7);
  padding-bottom: 16px;
  margin-bottom: 32px;
}
.course__footer {
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
}
.course__footer--disabled {
  pointer-events: none;
}
.course__footer:hover svg {
  fill: #ffda7e;
  transition: 0.3s;
}
.course__footer:hover .course__count {
  color: #ffda7e;
  transition: 0.3s;
}
.course__footer svg {
  transition: 0.3s;
}
.course__avatars {
  display: flex;
  align-items: center;
  margin-right: 16px;
}
.course__img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}
.course__avatar {
  position: relative;
  width: 42px;
  height: 42px;
  border: 3px solid #343c42;
  border-radius: 100%;
  background: #56636d;
  margin-left: -12px;
  overflow: hidden;
}
.course__avatar--0 {
  z-index: 0;
}
.course__avatar--1 {
  z-index: 1;
}
.course__avatar--2 {
  z-index: 2;
}
.course__avatar:first-of-type {
  margin-left: -3px;
}
.course__icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.course__count {
  color: rgba(255, 255, 255, 0.5);
  margin-right: auto;
  transition: 0.3s;
}
.course__grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-bottom: 16px;
}
.course__item {
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: calc(50% - 16px);
  color: rgba(255, 255, 255, 0.7);
  margin: 0 16px 16px 0;
  border-radius: 8px;
  padding: 16px;
}
.course__subtitle {
  margin-bottom: 8px;
  font-weight: 700;
  color: white;
}
.course__pricebox {
  margin-bottom: 12px;
}
.course__pricebox:last-of-type {
  margin-bottom: 0;
}
.course__key {
  color: #ffffff;
  opacity: 0.7;
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 4px;
}
.course__x {
  color: rgba(255, 255, 255, 0.7);
}
.course__folder {
  display: block;
  font-size: 12px;
  line-height: 18px;
  color: #ffda7e;
  margin-top: 32px;
  cursor: pointer;
  transition: 0.3s;
}
.course__folder:hover {
  color: #857b5a;
  transition: 0.3s;
}

@media only screen and (max-width: 600px) {
  .course__item {
    width: 100%;
  }
  .course__count {
    width: 200px;
  }
}
</style>
