<template>
  <div class="newPost">
    <div class="newPost__header">
      Редактирование курса
      <CloseSvg class="closeSvg closeSvg--white" @click="emit('toggleEditing')" />
    </div>
    <div class="newPost__content">
      <CustomInput
        class="newPost__item newPost__item--full"
        :label="'Заголовок'"
        :placeholder="'Заголовок'"
        :value="title"
        @inputs="value => (title = value)"
      />
      <CustomInput
        class="newPost__item newPost__item--half"
        :label="'Стоимость без отработки'"
        :placeholder="'Стоимость без отработки'"
        :value="priceStart"
        @inputs="value => (priceStart = value)"
      />
      <CustomInput
        class="newPost__item newPost__item--half"
        :label="'Стоимость с отработкой'"
        :placeholder="'Стоимость с отработкой'"
        :value="priceFull"
        @inputs="value => (priceFull = value)"
      />
      <CustomTextarea
        class="newPost__item"
        :label="'Описание'"
        :placeholder="'Описание'"
        :value="description"
        @inputs="value => (description = value)"
      />
      <CustomTextarea
        class="newPost__item"
        :label="'Что ожидает'"
        :placeholder="'Что ожидает'"
        :value="expectation"
        @inputs="value => (expectation = value)"
      />
      <CustomTextarea
        class="newPost__item"
        :label="'Программа'"
        :placeholder="'Программа'"
        :value="program"
        @inputs="value => (program = value)"
      />
      <CustomTextarea
        class="newPost__item"
        :label="'Что получаешь'"
        :placeholder="'Что получаешь'"
        :value="outcome"
        @inputs="value => (outcome = value)"
      />
      <CustomInput
        class="newPost__item newPost__item--half"
        :label="'Месяцев(период)'"
        :placeholder="'Месяцев(период)'"
        :value="durationMonths"
        @inputs="value => (durationMonths = value)"
      />
      <CustomInput
        class="newPost__item newPost__item--half"
        :label="'Дней(период)'"
        :placeholder="'Дней(период)'"
        :value="durationDays"
        @inputs="value => (durationDays = value)"
      />
      <CustomInput
        class="newPost__item newPost__item--half"
        :label="'Никнейм курса'"
        :placeholder="'Никнейм курса'"
        :value="nickname"
        @inputs="value => (nickname = value)"
      />
      <CustomInput
        class="newPost__item newPost__item--half"
        :label="'Посещений (шт)'"
        :placeholder="'Посещений (шт)'"
        :value="views"
        @inputs="value => (views = value)"
      />
      <CustomSelectbox
        class="profile__item profile__item--half"
        :label="'Категория'"
        :key-name="'name'"
        :name="'category'"
        :value="{ id: category }"
        :options="[
          { id: 'new', name: 'Новичкам' },
          { id: 'combo', name: 'Комбо' },
          { id: 'experienced', name: 'Опытным' },
          { id: 'masterclass', name: 'Мастеркласс' },
          { id: 'woman', name: 'Женский' }
        ]"
        @inputs="value => (category = value.id)"
      />
      <CustomSelectbox
        class="profile__item profile__item--half"
        :label="'Принадлежность'"
        :key-name="'name'"
        :name="'affiliation'"
        :value="{ id: affiliation }"
        :options="[
          { id: 'inner', name: 'Внутреннее обучение' },
          { id: 'kontora_tseh', name: 'Контора/Цех' },
          { id: 'manufactura', name: 'Мануфактура' }
        ]"
        @inputs="value => (affiliation = value.id)"
      />
      <CustomInput
        class="newPost__item newPost__item--full"
        :label="'Дата курса'"
        :value="date"
        :type="'date'"
        @inputs="value => (date = value)"
      />

      <img v-if="selectedImgSrc != ''" class="newPost__image" :src="selectedImgSrc" />

      <div class="newPost__footer">
        <div class="newPost__load">
          <AttachmentSvg class="newPost__icon" />
          <div v-if="selectedImgSrc == ''">Загрузить обложкку</div>
          <div v-else>Изменить обложкку</div>
          <input class="newPost__files" type="file" @change="selectImage" />
        </div>

        <!-- <div v-if="selectedImgSrc != ''" class="newPost__load" @click="removeImage">
          <BinSvg class="newPost__icon" />
          <div>Удалить обложкку</div>
        </div> -->

        <Button :disabled="title == ''" :loading="loading" :success="success" @click="update" />
      </div>
    </div>
  </div>
</template>

<script setup>
// Svg
import CloseSvg from '@/components/svg/CloseSvg'
import AttachmentSvg from '@/components/svg/AttachmentSvg'
import BinSvg from '@/components/svg/BinSvg'

// Components
import CustomInput from '@/components/CustomInput'
import CustomTextarea from '@/components/CustomTextarea'
import CustomSelectbox from '@/components/CustomSelectbox'
import Button from '@/components/Button'

// Composables
import useCourse from '@/mixins/useCourse'
import buttonMixin from '@/mixins/buttonMixin'

import { ref, toRef } from 'vue'

const emit = defineEmits(['toggleEditing'])
const props = defineProps({
  course: {
    type: Object,
    default () {
      return {}
    }
  }
})

const { loading, success, buttonLoad, buttonSuccess } = buttonMixin()
const propsRef = toRef(props, 'course')
const title = ref('')
const priceStart = ref('')
const priceFull = ref('')
const views = ref('')
const date = ref('')
const description = ref('')
const expectation = ref('')
const program = ref('')
const outcome = ref('')
const category = ref('')
const nickname = ref('')
const durationMonths = ref('')
const durationDays = ref('')
const affiliation = ref('')
const selectedImgSrc = ref('')
const imgFile = ref('')
const {
  courseId,
  courseTitle,
  coursePriceStart,
  coursePriceFull,
  courseViews,
  courseDate,
  courseDescription,
  courseExpectation,
  courseProgram,
  courseOutcome,
  courseCategory,
  courseNickname,
  courseDurationMonths,
  courseDurationDays,
  courseAffiliation,
  courseImgFile,
  toggleCourseNew,
  updateCourse
} = useCourse()

title.value = propsRef.value.course
priceStart.value = propsRef.value.priceStart
priceFull.value = propsRef.value.priceFull
views.value = propsRef.value.views
description.value = propsRef.value.description
expectation.value = propsRef.value.expectation
program.value = propsRef.value.program
outcome.value = propsRef.value.outcome
category.value = propsRef.value.type
nickname.value = propsRef.value.nickname
durationMonths.value = propsRef.value.durationMonths
durationDays.value = propsRef.value.durationDays
date.value = propsRef.value.date
affiliation.value = propsRef.value.category
if (propsRef.value.image) selectedImgSrc.value = `https://barberkuzma.ru:1414${propsRef.value.image.url}`

const selectImage = e => {
  imgFile.value = e.target.files
  selectedImgSrc.value = URL.createObjectURL(e.target.files[0])
}

const update = async () => {
  courseId.value = propsRef.value.id
  courseTitle.value = title.value
  coursePriceStart.value = priceStart.value
  coursePriceFull.value = priceFull.value
  courseViews.value = views.value
  courseDate.value = date.value
  courseDescription.value = description.value
  courseExpectation.value = expectation.value
  courseProgram.value = program.value
  courseOutcome.value = outcome.value
  courseCategory.value = category.value
  courseNickname.value = nickname.value
  courseDurationMonths.value = durationMonths.value
  courseDurationDays.value = durationDays.value
  courseAffiliation.value = affiliation.value
  courseImgFile.value = imgFile.value

  buttonLoad()
  await updateCourse()
  buttonSuccess()
  emit('toggleEditing')
}
</script>

<style scoped>
.newPost {
  background: #343c42;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  margin-bottom: 30px;
}
.newPost__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  border-bottom: 1px solid #5d6368;
  letter-spacing: 0.02em;
}
.newPost__content {
  padding: 16px 40px;
  display: flex;
  flex-wrap: wrap;
}
.newPost__footer {
  display: flex;
  align-items: center;
  flex: 1;
  margin-top: 16px;
}
.newPost__load {
  position: relative;
  color: #71777b;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 18px;
}
.newPost__load:last-of-type {
  margin-right: auto;
}
.newPost__files {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  font-size: 0;
  text-indent: -100px px;
  cursor: pointer;
}
.newPost__icon {
  margin-right: 8px;
}
.newPost__item {
  margin-bottom: 24px;
}
.newPost__item--full {
  width: 100%;
}
.newPost__item--half {
  width: calc(50% - 8px);
}
.newPost__image {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .newPost__content {
    padding: 16px;
  }
  .newPost__footer {
    display: block;
    margin-top: 0;
  }
  .newPost__image {
    margin-bottom: 16px;
  }
  .newPost__load {
    margin-bottom: 16px;
  }
}
</style>
