<template>
  <section class="news">
    <div class="news__content">
      <div v-if="offices.length > 0 && managers">
        <Back :to="`/team/${$route.params.net}`" />

        <div v-if="managers.length > 0" class="block">
          <div class="block__header">
            Руководители
          </div>

          <Worker v-for="manager in managers" :key="manager.id" :worker="manager" />
        </div>

        <div class="block block--special">
          <Salon :name="`Колл-центр`" :link="`${$route.fullPath}/call-center`" :special="true" />
        </div>

        <div class="block">
          <div class="block__header">Список салонов</div>
          <Salon
            v-for="office in offices"
            :id="office.id"
            :key="office.id"
            :name="office.name"
            :workers="office.workers"
            :year="office.year"
            :link="office.link"
          />
        </div>
      </div>
      <div v-else class="news__loader">
        <Loader />
      </div>
    </div>

    <div class="news__side">
      <Note :title="'Команда управления'" />
    </div>
  </section>
</template>

<script setup>
import Salon from '@/components/team/Salon'
import Worker from '@/components/team/Worker'
import Loader from '@/components/Loader'
import Note from '@/components/Note'
import Back from '@/components/Back'
import authorize from '@/mixins/authorize'
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()
const offices = ref([])
const managers = ref('')
const currentNet = ref('')
const currentCity = ref('')

const getCityData = async () => {
  const data = await fetch(`https://barberkuzma.ru:1414/cities?nameEng=${router.currentRoute.value.params.city}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
  const response = await data.json()
  currentCity.value = response[0]
}

const getNetData = async () => {
  const data = await fetch(`https://barberkuzma.ru:1414/barbershops?nameEng=${router.currentRoute.value.params.net}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
  const response = await data.json()
  currentNet.value = response[0]
}

const getOffices = async () => {
  const data = await fetch(
    `https://barberkuzma.ru:1414/adresses?barbershop.nameEng=${router.currentRoute.value.params.net}&city.nameEng=${router.currentRoute.value.params.city}`,
    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
  )
  const response = await data.json()

  for (const office of response) {
    processOffcies(office)
  }
}

const processOffcies = async office => {
  const dataWorkers = await fetch(`https://barberkuzma.ru:1414/staff?profileAdress=${office.id}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
  const responseWorkers = await dataWorkers.json()

  offices.value.push({
    id: office.id,
    name: office.adress,
    year: office.foundationYear,
    workers: responseWorkers.length,
    link: `${router.currentRoute.value.fullPath}/${office.url}`
  })
}

const getManagers = async () => {
  const data = await fetch(`https://barberkuzma.ru:1414/users`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
  const response = await data.json()

  managers.value = response.filter(manager => {
    let hasProfile = manager.profile !== null && manager.profile.length !== ''
    let hasProfileBarbershop = null
    let hasProfileCity = null
    let matchesCurrentNet = null
    let matchesCurrentCity = null
    let isManager = manager.positions.filter(position => position.isManager).length > 0

    if (hasProfile) {
      hasProfileBarbershop =
        manager.profile.profileBarbershop !== null && manager.profile.profileBarbershop.length !== ''
      hasProfileCity = manager.profile.profileCity !== null && manager.profile.profileCity.length !== ''
    }

    if (hasProfileBarbershop) {
      matchesCurrentNet = manager.profile.profileBarbershop.id == currentNet.value.id
    }

    if (hasProfileCity) {
      matchesCurrentCity = manager.profile.profileCity.id == currentCity.value.id
    }

    return hasProfile && hasProfileBarbershop && hasProfileCity && matchesCurrentNet && matchesCurrentCity && isManager
  })
}

authorize()
getCityData()
getNetData()
getManagers()
getOffices()
</script>

<style scoped>
.news {
  background: #1d232a;
  display: flex;
  align-items: flex-start;
  padding: 24px 50px 0 50px;
}
.news__content {
  position: relative;
  margin-right: 30px;
  flex: 1;
}
.news__loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news__side {
  width: 329px;
  position: sticky;
  top: 24px;
  height: fit-content;
}

.block {
  background: #343c42;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 30px;
}
.block--special {
  background: #2a3137;
  border-color: #ffe6a9;
}
.block:last-of-type {
  margin-bottom: 50px;
}
.block__header {
  display: flex;
  align-items: center;
  padding: 16px;
  letter-spacing: 0.02em;
  border-bottom: 1px solid #5d6368;
}
.block__header--noborder {
  border-bottom: none;
}

@media only screen and (max-width: 600px) {
  .news {
    background: unset;
    display: unset;
    align-items: unset;
    padding: 0;
  }
  .news__content {
    margin-right: unset;
    flex: unset;
    padding: 16px 16px 88px 16px;
  }
  .news__side {
    display: none;
  }
  .news__loader {
    margin-top: 150px;
  }

  .block {
    margin-bottom: 16px;
  }
  .block:last-of-type {
    margin-bottom: 0;
  }
}
</style>
