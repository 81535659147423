<template>
  <div
    v-if="category"
    :class="[
      'label',
      {
        'label--green': category == 'inner',
        'label--red': category == 'kontora_tseh',
        'label--blue': category == 'manufactura'
      }
    ]"
  >
    <div
      :class="[
        'label__dot',
        {
          'label__dot--green': category == 'inner',
          'label__dot--red': category == 'kontora_tseh',
          'label__dot--blue': category == 'manufactura'
        }
      ]"
    ></div>
    <div
      :class="[
        'label__text',
        {
          'label__text--green': category == 'inner',
          'label__text--red': category == 'kontora_tseh',
          'label__text--blue': category == 'manufactura'
        }
      ]"
    >
      {{ categoryName }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  category: {
    type: String,
    default () {
      return null
    }
  }
})

const categoryName = computed(() => {
  if (props.category == 'inner') {
    return 'Внутреннее обучение'
  } else if (props.category == 'kontora_tseh') {
    return 'Контора / цех'
  } else {
    return 'Курсы мануфактуры'
  }
})
</script>

<style scoped>
.label {
  border: 1px solid;
  border-radius: 4px;
  padding: 7px 16px;
  display: flex;
  align-items: center;
}
.label--green {
  border-color: #65d024;
}
.label--red {
  border-color: #ffb9b9;
}
.label--blue {
  border-color: #a1d6ed;
}
.label__dot {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  margin-right: 7px;
}
.label__dot--green {
  background: #65d024;
}
.label__dot--red {
  background: #ffb9b9;
}
.label__dot--blue {
  background: #a1d6ed;
}
.label__text {
  font-size: 12px;
  line-height: 18px;
  text-transform: lowercase;
}
.label__text--green {
  color: #65d024;
}
.label__text--red {
  color: #ffb9b9;
}
.label__text--blue {
  color: #a1d6ed;
}
</style>
