<template>
  <section class="sidebar">
    <div class="sidebar__company">BarberTeam</div>
    <router-link
      :class="['sidebar__item', { 'sidebar__item--active': $route.fullPath.split('/')[1] == 'news' }]"
      to="/news"
    >
      <NewsSvg class="sidebar__icon" />
      Новости
    </router-link>

    <router-link
      :class="['sidebar__item', { 'sidebar__item--active': $route.fullPath.split('/')[1] == 'education' }]"
      to="/education"
    >
      <EducationSvg class="sidebar__icon" />
      Обучение
    </router-link>

    <router-link
      :class="['sidebar__item', { 'sidebar__item--active': $route.fullPath.split('/')[1] == 'team' }]"
      to="/team"
    >
      <TeamSvg class="sidebar__icon" />
      Команда управления
    </router-link>

    <router-link
      :class="['sidebar__item', { 'sidebar__item--active': $route.fullPath.split('/')[1] == 'competition' }]"
      to="/competition"
    >
      <CompetitionSvg class="sidebar__icon" />
      Конкурсы
    </router-link>

    <router-link
      :class="['sidebar__item', { 'sidebar__item--active': $route.fullPath.split('/')[1] == 'career' }]"
      to="/career"
    >
      <CareerSvg class="sidebar__icon" />
      Карьера
    </router-link>

    <router-link
      :class="['sidebar__item', { 'sidebar__item--active': $route.fullPath.split('/')[1] == 'settings' }]"
      to="/settings"
    >
      <SettingsSvg class="sidebar__icon" />
      Настройки
    </router-link>

    <router-link
      :class="['sidebar__item', { 'sidebar__item--active': $route.fullPath.split('/')[1] == 'support' }]"
      to="/support"
    >
      <SupportSvg class="sidebar__icon" />
      Поддержка
    </router-link>

    <div class="sidebar__item sidebar__item--last" @click="logOut">
      <LogoutSvg class="sidebar__icon" />
      Выход
    </div>
  </section>
</template>

<script setup>
import NewsSvg from '@/components/svg/sidebar/NewsSvg'
import EducationSvg from '@/components/svg/sidebar/EducationSvg'
import TeamSvg from '@/components/svg/sidebar/TeamSvg'
import CompetitionSvg from '@/components/svg/sidebar/CompetitionSvg'
import CareerSvg from '@/components/svg/sidebar/CareerSvg'
import SettingsSvg from '@/components/svg/sidebar/SettingsSvg'
import SupportSvg from '@/components/svg/sidebar/SupportSvg'
import LogoutSvg from '@/components/svg/sidebar/LogoutSvg'

import { ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const filter = ref('news')

const logOut = () => {
  localStorage.removeItem('token')
  router.push({ path: '/login' })
}
</script>

<style scoped>
.sidebar {
  position: sticky;
  top: 0;
  width: 275px;
  height: 100vh;
  background: #15191c;
}
.sidebar__company {
  font-size: 22px;
  line-height: 24px;
  color: #ffda7e;
  padding: 32px 36px 23px 36px;
}
.sidebar__item {
  position: relative;
  padding: 26px 16px 26px 36px;
  display: flex;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  transition: 0.3s;
}
.sidebar__item::after {
  content: '';
  position: absolute;
  right: 16px;
  top: 16px;
  width: 4px;
  height: calc(100% - 32px);
  background: #e0b184;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}
.sidebar__item--last:after {
  content: '';
  position: absolute;
  height: 1px;
  width: calc(100% - 62px);
  top: 0;
  left: 31px;
  background: #5b5e60;
  opacity: 1;
  visibility: visible;
}
.sidebar__item:hover {
  background: #343c42;
  transition: 0.3s;
}
.sidebar__item--active {
  background: #343c42;
  pointer-events: none;
  font-weight: 700;
  cursor: default;
  transition: 0.3s;
}
.sidebar__item--active::after {
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
}
.sidebar__icon {
  margin-right: 8px;
}
@media only screen and (max-width: 600px) {
  .sidebar {
    display: none;
  }
}
</style>
