import { ref } from 'vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const isOpenModal = ref(false)
const courseName = ref(0)
const courseId = ref(0)
const records = ref([])

export default () => {
  const toggleModal = () => {
    if (isOpenModal.value) {
      const modalRecords = document.querySelector('.modalRecords')
      enableBodyScroll(modalRecords)
      isOpenModal.value = false
    } else {
      isOpenModal.value = true
      setTimeout(() => {
        const modalRecords = document.querySelector('.modalRecords')
        disableBodyScroll(modalRecords)
      }, 0)
    }
  }

  return {
    records,
    courseId,
    courseName,
    isOpenModal,
    toggleModal
  }
}
