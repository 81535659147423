<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
    <g clip-path="url(#succ)">
      <path
        d="M18.0041 7L16.5941 5.59L10.2541 11.93L11.6641 13.34L18.0041 7ZM22.2441 5.59L11.6641 16.17L7.48406 12L6.07406 13.41L11.6641 19L23.6641 7L22.2441 5.59ZM0.414062 13.41L6.00406 19L7.41406 17.59L1.83406 12L0.414062 13.41Z"
        fill="#27AE60"
      />
    </g>
    <defs>
      <clipPath id="succ">
        <rect width="24" height="24" fill="white" transform="translate(0.00390625)" />
      </clipPath>
    </defs>
  </svg>
</template>
