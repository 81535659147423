<template>
  <section class="profile">
    <div class="profile__header">Безопасность</div>

    <div class="profile__body">
      <div class="profile__inputs">
        <CustomInput
          class="profile__item--full profile__item"
          :label="'Текущий пароль'"
          :placeholder="''"
          :value="password"
          :alert="alertPassword"
          @inputs="value => (password = value)"
        />
        <CustomInput
          class="profile__item--full profile__item"
          :label="'Новый пароль'"
          :placeholder="''"
          :value="newPassword"
          :alert="alertNewPassword"
          @inputs="value => (newPassword = value)"
        />
        <CustomInput
          class="profile__item--full"
          :label="'Повторите новый пароль'"
          :placeholder="''"
          :value="passwordConfirmation"
          :alert="alertPasswordConfirmation"
          @inputs="value => (passwordConfirmation = value)"
        />
      </div>

      <Button class="profile__button" :loading="loading" :success="success" @click="validate" />
    </div>
  </section>
</template>

<script setup>
import CustomInput from '@/components/CustomInput'
import Button from '@/components/Button'
import buttonMixin from '@/mixins/buttonMixin'

import { ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const password = ref('')
const newPassword = ref('')
const passwordConfirmation = ref('')
const alertPassword = ref('')
const alertNewPassword = ref('')
const alertPasswordConfirmation = ref('')
let { loading, success, buttonLoad, buttonSuccess } = buttonMixin()

const validate = () => {
  resetAlerts()

  if (password.value == '') {
    alertPassword.value = 'Введите текущий пароль'
  } else if (password.value != '' && password.value.length < 6) {
    alertPassword.value = 'Пароль должен состоять из 6 или более символов'
  } else if (newPassword.value == '') {
    alertNewPassword.value = 'Введите новый пароль'
  } else if (passwordConfirmation.value == '') {
    alertPasswordConfirmation.value = 'Повторите новый пароль'
  } else if (newPassword.value != passwordConfirmation.value) {
    alertPasswordConfirmation.value = 'Не соотвествует новому паролю'
  } else {
    updateServerData()
  }
}

const resetAlerts = () => {
  alertPassword.value = ''
  alertNewPassword.value = ''
  alertPasswordConfirmation.value = ''
}

const updateServerData = async () => {
  buttonLoad()

  const data = await fetch(`https://barberkuzma.ru:1414/password`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${store.state.user.id}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username: store.state.user.username,
      password: password.value,
      newPassword: newPassword.value,
      passwordConfirmation: passwordConfirmation.value
    })
  })
  const response = await data.json()

  if (response.error && response.data == 'password') {
    alertPassword.value = response.message
  } else {
    buttonSuccess()
  }
}
</script>
