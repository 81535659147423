<template>
  <svg width="28" height="28" viewBox="0 0 28 28">
    <path
      d="M13.4167 25.6667C14.7117 25.6667 15.75 24.6283 15.75 23.3333H11.0833C11.0833 23.9522 11.3292 24.5457 11.7668 24.9833C12.2043 25.4208 12.7978 25.6667 13.4167 25.6667ZM21 14H20.4167C19.8333 14 19.25 13.9067 18.6667 13.755V17.8333C18.6667 18.9379 17.7712 19.8333 16.6667 19.8333H10.1667C9.0621 19.8333 8.16667 18.9379 8.16667 17.8333V12.25C8.16667 9.33334 10.5 7.00001 13.4167 7.00001C13.6267 7.00001 13.825 7.03501 14 7.05834C14.1167 5.90334 14.525 4.83001 15.1667 3.93168C15.0733 3.03334 14.3267 2.33334 13.4167 2.33334C12.4483 2.33334 11.6667 3.11501 11.6667 4.08334V4.87668C8.31833 5.67001 5.83333 8.66834 5.83333 12.25V18.6667L3.74162 20.7584C3.58691 20.9131 3.5 21.1229 3.5 21.3417C3.5 21.7973 3.86935 22.1667 4.32496 22.1667H22.5084C22.964 22.1667 23.3333 21.7973 23.3333 21.3417C23.3333 21.1229 23.2464 20.9131 23.0917 20.7584L21 18.6667V14Z"
      fill="white"
    />
    <path
      d="M20.4173 11.6667C22.669 11.6667 24.5007 9.835 24.5007 7.58333C24.5007 5.33167 22.669 3.5 20.4173 3.5C18.1657 3.5 16.334 5.33167 16.334 7.58333C16.334 9.835 18.1657 11.6667 20.4173 11.6667Z"
      fill="#DB6868"
    />
  </svg>
</template>
