<template>
  <div class="filter">
    <div :class="['filter__item', { 'filter__item--active': filter == '' }]" @click="filterEvents('')">
      Все
    </div>
    <div :class="['filter__item', { 'filter__item--active': filter == 'news' }]" @click="filterEvents('news')">
      Новости
    </div>
    <!-- <div :class="['filter__item', { 'filter__item--active': filter == 'competition' }]" @click="filter = 'competition'">
      Конкурсы
    </div> -->
    <div
      :class="['filter__item', { 'filter__item--active': filter == 'education' }]"
      @click="filterEvents('education')"
    >
      Обучение
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const filter = ref('')
const emit = defineEmits(['filterEvents'])

const filterEvents = type => {
  filter.value = type
  emit('filterEvents', type)
}
</script>

<style scoped>
.filter {
  background: #2a3137;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  overflow: hidden;
}
.filter__item {
  position: relative;
  padding: 28px 24px;
  font-size: 16px;
  line-height: 27px;
  color: rgba(255, 255, 255, 0.8);
  transition: 0.3s;
}
.filter__item--active {
  pointer-events: none;
  background: #343c42;
  color: white;
  transition: 0.3s;
}
.filter__item--active::after {
  content: '';
  position: absolute;
  right: 15px;
  top: 16px;
  height: 48px;
  width: 4px;
  background: #e0b184;
}
.filter__item:hover {
  cursor: pointer;
  background: #343c42;
  transition: 0.3s;
}
</style>
