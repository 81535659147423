<template>
  <svg width="81" height="80" viewBox="0 0 81 80" fill="none">
    <path
      d="M70.4275 22.6795C76.6155 33.3975 76.6155 46.6025 70.4275 57.3205C64.2395 68.0385 52.8035 74.641 40.4275 74.641C28.0514 74.641 16.6155 68.0385 10.4275 57.3205C4.23947 46.6025 4.23947 33.3975 10.4275 22.6795C16.6155 11.9615 28.0514 5.35898 40.4275 5.35898C52.8035 5.35898 64.2395 11.9615 70.4275 22.6795Z"
      fill="#F2F2F2"
      fill-opacity="0.1"
    />
    <path
      d="M41.6303 39.6275L48.0533 32.1919C48.161 32.0683 48.0704 31.8806 47.904 31.8806H45.9515C45.8365 31.8806 45.7264 31.9305 45.6505 32.0161L40.3531 38.1494L35.0557 32.0161C34.9823 31.9305 34.8722 31.8806 34.7547 31.8806H32.8021C32.6358 31.8806 32.5452 32.0683 32.6529 32.1919L39.0758 39.6275L32.6529 47.0631C32.6288 47.0906 32.6133 47.1243 32.6083 47.1602C32.6033 47.196 32.609 47.2325 32.6247 47.2652C32.6404 47.298 32.6655 47.3257 32.697 47.3451C32.7284 47.3644 32.7649 47.3746 32.8021 47.3744H34.7547C34.8697 47.3744 34.9798 47.3245 35.0557 47.2389L40.3531 41.1056L45.6505 47.2389C45.7239 47.3245 45.834 47.3744 45.9515 47.3744H47.904C48.0704 47.3744 48.161 47.1867 48.0533 47.0631L41.6303 39.6275Z"
      fill="#828282"
    />
  </svg>
</template>
