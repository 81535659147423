<template>
  <button class="btnPlain"><slot></slot></button>
</template>

<script setup></script>

<style scoped>
.btnPlain {
  display: block;
  padding: 16px;
  font-weight: 600;
  border: 1px solid #ffda7e;
  border-radius: 8px;
  color: #ffda7e;
  transition: 0.3s;
}
.btnPlain:hover {
  color: #ffe6a9;
  box-shadow: 0px 0px 14px rgb(255 218 126 / 40%);
  border-color: #ffe6a9;
  transition: 0.3s;
  cursor: pointer;
}
.btnPlain:disabled {
  pointer-events: none;
  opacity: 0.4;
}
</style>
