import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default () => {
  const store = useStore()
  const router = useRouter()

  const getUserData = async () => {
    const userId = JSON.parse(localStorage.getItem('userId'))
    const data = await fetch(`https://barberkuzma.ru:1414/users/${userId}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    })
    const response = await data.json()
    store.commit('setUser', response)
  }

  if (localStorage.getItem('token') != null) {
    getUserData()
  } else {
    router.push({ path: '/login' })
  }
}
