import { ref } from 'vue'

const courses = ref(null)

export default () => {
  const getCourses = async () => {
    const data = await fetch('https://barberkuzma.ru:1414/courses/', {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    })
    const response = await data.json()
    courses.value = response.filter(course => course.course != 'Новый курс')
  }

  return {
    courses,
    getCourses
  }
}
