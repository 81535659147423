<template>
  <section class="profile">
    <div class="profile__header">Образование</div>

    <div class="profile__body">
      <div v-for="(value, key) in education" :key="key" class="profile__inputs">
        <CustomInput
          class="profile__item profile__item--full"
          :label="'Учебное заведение'"
          :placeholder="'Учебное заведение'"
          :value="value.institution || ''"
          @inputs="newValue => (value.institution = newValue)"
        />
        <CustomInput
          class="profile__item profile__item--full"
          :label="'Факультет'"
          :placeholder="'Факультет'"
          :value="value.faculty || ''"
          @inputs="newValue => (value.faculty = newValue)"
        />
        <CustomInput
          class="profile__item profile__item--full"
          :label="'Специальность '"
          :placeholder="'Специальность '"
          :value="value.major || ''"
          @inputs="newValue => (value.major = newValue)"
        />
        <CustomInput
          class="profile__item profile__item--full"
          :label="'Форма обучения '"
          :placeholder="'Форма обучения '"
          :value="value.educationForm || ''"
          @inputs="newValue => (value.educationForm = newValue)"
        />
        <CustomInput
          class="profile__item profile__item--half"
          :label="'Начало обучения'"
          :value="value.educationStartDate || ''"
          :type="'date'"
          @inputs="newValue => (value.educationStartDate = newValue)"
        />
        <CustomInput
          class="profile__item profile__item--half"
          :label="'Конец обучения'"
          :value="value.educationEndDate"
          :type="'date'"
          @inputs="newValue => (value.educationEndDate = newValue)"
        />

        <div v-if="education.length > 1" class="profile__more">
          <button class="button button--plain" @click="removeItem(key)">
            удалить
          </button>
        </div>
      </div>

      <button class="button button--plain profile__button" @click="addItem">
        + добавить еще
      </button>

      <Button class="profile__button" :loading="loading" :success="success" @click="saveHandler" />
    </div>
  </section>
</template>

<script setup>
import CustomInput from '@/components/CustomInput'
import Button from '@/components/Button'
import buttonMixin from '@/mixins/buttonMixin'

import { useStore } from 'vuex'

const store = useStore()
let education = store.state.user.education.length === 0 ? [{}] : store.state.user.education
let { loading, success, buttonLoad, buttonSuccess } = buttonMixin()

const addItem = () => {
  const newEducationObj = Object.assign({}, education[0])
  delete newEducationObj.id
  education.push(newEducationObj)
  Object.keys(education.at(-1)).forEach(key => (education.at(-1)[key] = ''))
}

const removeItem = key => {
  education.splice(key, 1)
}

const removeEmptyEducations = () => {
  education.forEach((value, index) => {
    delete education[index].id
  })

  education = education.filter(education => {
    if (!Object.values(education).every(x => x === null || x === '')) return education
  })

  if (education.length === 0) education.push({})
}

const updateServerData = async () => {
  buttonLoad()

  await fetch(`https://barberkuzma.ru:1414/users/${store.state.user.id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ education: education })
  })

  buttonSuccess()
}

const saveHandler = () => {
  removeEmptyEducations()
  updateServerData()
}
</script>
