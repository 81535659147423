<template>
  <div class="selectbox">
    <div class="selectbox__label">{{ label }}</div>
    <select
      :id="name"
      class="selectbox__selectbox"
      :name="name"
      :value="validatedValue"
      @input="processValue($event.target.value)"
    >
      <option value="0">{{ label }}</option>
      <option v-for="(option, index) in options" :key="index" :value="option.id">
        {{ option[keyName] }}
      </option>
    </select>
    <svg class="selectbox__icon" width="22" height="21" viewBox="0 0 22 21" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.8097 8.21181C15.0582 8.49421 15.0582 8.95208 14.8097 9.23449L11.4961 13L8.18248 9.23449C7.93396 8.95208 7.93396 8.49421 8.18248 8.2118C8.431 7.9294 8.83392 7.9294 9.08243 8.2118L11.4961 10.9546L13.9098 8.2118C14.1583 7.9294 14.5612 7.9294 14.8097 8.21181Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'CustomSelectbox',
  props: ['label', 'keyName', 'name', 'value', 'options'],
  computed: {
    validatedValue () {
      if (this.value == null) {
        return 0
      } else if (this.name == 'gender') {
        let inArr = Object.values(this.options).filter(option => option.id == this.value).length > 0
        return inArr ? this.value : 0
      } else {
        let inArr = Object.values(this.options).filter(option => option.id == this.value.id).length > 0
        return inArr ? this.value.id : 0
      }
    }
  },
  methods: {
    processValue (value) {
      if (value == 0) {
        this.$emit('inputs', null)
      } else if (this.name == 'gender') {
        let processedValue = Object.values(this.options).filter(option => option.id == value)
        this.$emit('inputs', processedValue[0].id)
      } else {
        let processedValue = Object.values(this.options).filter(option => option.id == value)
        this.$emit('inputs', processedValue[0])
      }
    }
  }
}
</script>

<style>
.selectbox {
  position: relative;
  margin: 0 8px 0 0;
}
.selectbox__label {
  font-size: 14px;
  line-height: 21px;
  opacity: 0.6;
  margin-bottom: 8px;
}
.selectbox__selectbox {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 4px;
  height: 37px;
  background: transparent;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  padding: 0 16px;
  transition: 0.3s;
}
.selectbox__selectbox:hover {
  border-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 4px rgba(255, 218, 126, 0.4);
  transition: 0.3s;
}
.selectbox__icon {
  position: absolute;
  right: 16px;
  bottom: 8px;
  pointer-events: none;
}
</style>
