<template>
  <div class="btnPlus">
    <div class="btnPlus__content">
      <svg width="12" height="14" viewBox="0 0 12 14" fill="none">
        <path
          d="M6.97046 5.84766H11.7283V7.89844H6.97046V13.2891H4.79077V7.89844H0.032959V5.84766H4.79077V0.867188H6.97046V5.84766Z"
          fill="#FFDA7E"
        />
      </svg>
    </div>
  </div>
</template>

<script setup></script>

<style>
.btnPlus {
  position: fixed;
  right: 16px;
  bottom: 88px;
  z-index: 1;
  width: 60px;
  height: 60px;
  background: #3f464b;
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.55);
  border-radius: 100%;
  padding: 4px;
}
.btnPlus__content {
  border: 3px solid #ffda7e;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
