<template>
  <section class="authorization">
    <div class="authorization__stripe1"></div>
    <div class="authorization__stripe2"></div>
    <div class="authorization__content">
      <div class="authorization__title">Авторизация</div>
      <div class="authorization__form">
        <div class="authorization__cover"></div>
        <input v-model="identifier" class="authorization__input" placeholder="Логин" @keydown="resetData" />
        <input
          v-model="password"
          class="authorization__input"
          type="password"
          placeholder="Пароль"
          @keydown="resetData"
        />

        <div v-if="!validated" class="authorization__alert">
          <div class="authorization__line"></div>
          <div v-if="!identifier && password">Введите логин</div>
          <div v-if="!password && identifier">Введите пароль</div>
          <div v-if="!identifier && !password">Введите логин и пароль</div>
        </div>

        <div v-if="!authorized" class="authorization__alert">
          <div class="authorization__line"></div>
          <div>Не верно введен логин или пароль</div>
        </div>

        <div v-if="error" class="authorization__alert">
          <div class="authorization__line"></div>
          <div>Ошибка при попытке авторизации</div>
        </div>

        <button class="authorization__submit" @click="validate">Вход</button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()
const identifier = ref('')
const password = ref('')
const validated = ref(true)
const authorized = ref(true)
const error = ref(false)

const validate = () => {
  identifier.value == '' || password.value == '' ? (validated.value = false) : submit()
}

const processPositiveLogin = response => {
  localStorage.setItem('token', response.jwt)
  localStorage.setItem('userId', JSON.stringify(response.user.id))
  router.push({ path: '/news' })
}

const processNegativeLogin = () => {
  authorized = false
}
const processError = error => {
  error = true
  console.log('Ошибка при авторизации:', error.response)
}

const resetData = () => {
  validated.value = true
  authorized.value = true
  error.value = false
}

const submit = async () => {
  try {
    const data = await fetch('https://barberkuzma.ru:1414/auth/local', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      body: JSON.stringify({
        identifier: identifier.value,
        password: password.value
      })
    })
    const response = await data.json()

    Object.hasOwn(response, 'jwt') ? processPositiveLogin(response) : processNegativeLogin()
  } catch (error) {
    processError(error)
  }
}
</script>

<style scoped>
.authorization {
  background: #15191c;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.authorization__content {
  width: 407px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.authorization__title {
  font-size: 42px;
  line-height: 63px;
  color: #ffda7e;
  opacity: 0.6;
  margin-bottom: 32px;
}
.authorization__stripe1 {
  position: absolute;
  left: 100px;
  height: 2px;
  width: 100%;
  opacity: 0.3;
  background: linear-gradient(95deg, #ac8967 30%, #ffda7e 70%);
  transform: rotate(-59.98deg);
}
.authorization__stripe2 {
  position: absolute;
  left: 90px;
  height: 2px;
  width: 100%;
  opacity: 0.3;
  background: linear-gradient(95deg, #ac8967 30%, #ffda7e 70%);
  transform: rotate(-72.87deg);
}
.authorization__form {
  position: relative;
  width: 100%;
  background: #23282f;
  padding: 32px;
}
.authorization__cover {
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  z-index: -1;
  background-image: linear-gradient(to bottom right, #23282f, 85%, #ffda7e);
}
.authorization__alert {
  display: flex;
  align-items: center;
  color: #ff7a7a;
  position: absolute;
  top: 176px;
}
.authorization__line {
  width: 1px;
  height: 14px;
  background: #ff7a7a;
  margin-right: 6px;
}
.authorization__input {
  width: 100%;
  height: 56px;
  margin-bottom: 24px;
  background: #343c42;
  border-radius: 4px;
  color: white;
  padding: 0 16px;
  transition: 0.3s;
}
.authorization__input::placeholder {
  color: white;
}
.authorization__input:last-of-type {
  margin-bottom: 0;
}
.authorization__input:focus {
  background: #4a555d;
  transition: 0.3s;
}
.authorization__submit {
  height: 56px;
  border: 1px solid #ffda7e;
  color: #ffda7e;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  margin: 60px 0 20px 0;
  transition: 0.3s;
}
.authorization__submit:hover {
  color: #ffe7ac;
  border-color: #ffe7ac;
  transition: 0.3s;
}
.authorization__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.authorization__reg {
  color: #ffda7e;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  transition: 0.3s;
}
.authorization__reg:hover {
  color: #ffe7ac;
  border-bottom: 1px solid #ffe7ac;
  transition: 0.3s;
}
.authorization__pass {
  cursor: pointer;
  transition: 0.3s;
}
.authorization__pass:hover {
  color: #7e8f9b;
  transition: 0.3s;
}

@media only screen and (max-width: 600px) {
  .authorization__stripe1 {
    display: none;
  }
  .authorization__stripe2 {
    display: none;
  }
  .authorization__title {
    font-size: 34px;
    line-height: 63px;
    margin-bottom: 47px;
  }
  .authorization__form {
    background: #15191c;
    padding: 0 16px;
  }
  .authorization__cover {
    display: none;
  }
  .authorization__alert {
    top: 145px;
  }
  .authorization__submit {
    color: #15191c;
    background: #ffda7e;
  }
  .authorization__submit:hover {
    color: initial;
  }
}
</style>
