import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Maska from 'maska'
import VueLazyLoad from 'vue3-lazyload'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(Maska)
app.use(VueLazyLoad)
app.mount('#app')
