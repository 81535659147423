<template>
  <section class="profile">
    <div class="profile__header">Карьера</div>

    <div class="profile__body">
      <div v-for="(value, key) in career" :key="key" class="profile__inputs">
        <CustomInput
          class="profile__item profile__item--full"
          :label="'Место работы'"
          :placeholder="'Укажите компанию'"
          :value="value.careerWorkplace"
          @inputs="newValue => (value.careerWorkplace = newValue)"
        />
        <CustomInput
          class="profile__item profile__item--full"
          :label="'Страна'"
          :placeholder="'Укажите страну'"
          :value="value.careerCountry"
          @inputs="newValue => (value.careerCountry = newValue)"
        />
        <CustomInput
          class="profile__item profile__item--full"
          :label="'Город'"
          :placeholder="'Укажите город'"
          :value="value.careerCity"
          @inputs="newValue => (value.careerCity = newValue)"
        />
        <CustomInput
          class="profile__item profile__item--full"
          :label="'Должность'"
          :placeholder="'Укажите должность'"
          :value="value.careerPosition"
          @inputs="newValue => (value.careerPosition = newValue)"
        />
        <CustomInput
          class="profile__item profile__item--half"
          :label="'Начало работы'"
          :value="value.careerStartDate"
          :type="'date'"
          @inputs="newValue => (value.careerStartDate = newValue)"
        />
        <CustomInput
          class="profile__item profile__item--half"
          :label="'Окончание работы'"
          :value="value.careerEndDate"
          :type="'date'"
          @inputs="newValue => (value.careerEndDate = newValue)"
        />

        <div v-if="career.length > 1" class="profile__more">
          <button class="button button--plain" @click="removeItem(key)">
            удалить
          </button>
        </div>
      </div>

      <button class="button button--plain profile__button" @click="addItem">
        + добавить еще
      </button>

      <Button class="profile__button" :loading="loading" :success="success" @click="saveHandler" />
    </div>
  </section>
</template>

<script setup>
import CustomInput from '@/components/CustomInput'
import Button from '@/components/Button'
import buttonMixin from '@/mixins/buttonMixin'

import { useStore } from 'vuex'

const store = useStore()
let career = store.state.user.career.length === 0 ? [{}] : store.state.user.career
let { loading, success, buttonLoad, buttonSuccess } = buttonMixin()

const addItem = () => {
  const newCareerObj = Object.assign({}, career[0])
  delete newCareerObj.id
  career.push(newCareerObj)
  Object.keys(career.at(-1)).forEach(key => (career.at(-1)[key] = ''))
}

const removeItem = key => {
  career.splice(key, 1)
}

const removeEmptyCareers = () => {
  career.forEach((value, index) => {
    delete career[index].id
  })

  career = career.filter(career => {
    if (!Object.values(career).every(x => x === null || x === '')) return career
  })

  if (career.length === 0) career.push({})
}

const updateServerData = async () => {
  buttonLoad()

  await fetch(`https://barberkuzma.ru:1414/users/${store.state.user.id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ career: career })
  })

  buttonSuccess()
}

const saveHandler = () => {
  removeEmptyCareers()
  updateServerData()
}
</script>
