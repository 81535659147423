<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M0 5C0 4.44772 0.488417 4 1.09091 4H22.9091C23.5116 4 24 4.44772 24 5C24 5.55228 23.5116 6 22.9091 6H1.09091C0.488417 6 0 5.55228 0 5Z"
      fill="white"
    />
    <path
      d="M0 12C0 11.4477 0.488417 11 1.09091 11H22.9091C23.5116 11 24 11.4477 24 12C24 12.5523 23.5116 13 22.9091 13H1.09091C0.488417 13 0 12.5523 0 12Z"
      fill="white"
    />
    <path
      d="M0 19C0 18.4477 0.488417 18 1.09091 18H22.9091C23.5116 18 24 18.4477 24 19C24 19.5523 23.5116 20 22.9091 20H1.09091C0.488417 20 0 19.5523 0 19Z"
      fill="white"
    />
  </svg>
</template>
