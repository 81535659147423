<template>
  <div v-if="user" class="filter">
    <div :class="['filter__item', { 'filter__item--active': filter == 'profile' }]" @click="update('profile')">
      Профиль
    </div>
    <div
      v-if="user.profile && user.profile.profileBarbershop && user.profile.profileBarbershop.name"
      :class="['filter__item', { 'filter__item--active': filter == 'work' }]"
      @click="update('work')"
    >
      Работа
    </div>
    <div
      v-if="courses.length > 0"
      :class="['filter__item', { 'filter__item--active': filter == 'course' }]"
      @click="update('course')"
    >
      Курсы
    </div>
    <div
      v-if="user.social"
      :class="['filter__item', { 'filter__item--active': filter == 'socials' }]"
      @click="update('socials')"
    >
      Социальные сети
    </div>
    <div
      v-if="user.about && user.about.aboutAbout"
      :class="['filter__item', { 'filter__item--active': filter == 'about' }]"
      @click="update('about')"
    >
      О себе
    </div>
    <div
      v-if="user.interests"
      :class="['filter__item', { 'filter__item--active': filter == 'interests' }]"
      @click="update('interests')"
    >
      Интересы
    </div>
    <div
      v-if="user.education && user.education.length > 0"
      :class="['filter__item', { 'filter__item--active': filter == 'education' }]"
      @click="update('education')"
    >
      Образование
    </div>
    <div
      v-if="user.career && user.career.length > 0"
      :class="['filter__item', { 'filter__item--active': filter == 'career' }]"
      @click="update('career')"
    >
      Карьера
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
  user: {
    type: Object,
    default () {
      return {}
    }
  },
  courses: {
    type: Object,
    default () {
      return []
    }
  }
})

const filter = ref('profile')
const emit = defineEmits(['update'])

const update = type => {
  filter.value = type
  emit('update', type)
}

defineExpose({
  filter
})
</script>

<style scoped>
.filter {
  background: #2a3137;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  overflow: hidden;
}
.filter__item {
  position: relative;
  padding: 28px 24px;
  font-size: 16px;
  line-height: 27px;
  color: rgba(255, 255, 255, 0.8);
  transition: 0.3s;
}
.filter__item--active {
  pointer-events: none;
  background: #343c42;
  color: white;
  transition: 0.3s;
}
.filter__item--active::after {
  content: '';
  position: absolute;
  right: 15px;
  top: 16px;
  height: 48px;
  width: 4px;
  background: #e0b184;
}
.filter__item:hover {
  cursor: pointer;
  background: #343c42;
  transition: 0.3s;
}
</style>
