<template>
  <div>
    <section class="news">
      <div class="news__content">
        <Note v-if="isMobile" />
        <Subtitle v-if="isMobile" @click="toggleFilter" />

        <PostNew v-if="isOpenPostNew" />

        <div v-if="store.state.user && events && events.length > 0">
          <div v-for="(event, index) in events" :key="index">
            <Course v-if="Object.hasOwn(event, 'course')" :course="event" :is-editor-user="isEditorUser" />
            <Post v-else :post="event" :is-editor-user="isEditorUser" />
          </div>
        </div>

        <div v-else class="news__loader">
          <Loader />
        </div>
      </div>

      <FilterMobile ref="filterRef" @filter-events="filterEvents" />

      <div class="news__side">
        <Note />
        <Filter @filter-events="filterEvents" />

        <BtnPlain
          v-if="store.state.user && isEditorUser"
          class="news__sidebtn"
          :disabled="isOpenPostNew"
          @click="togglePostNew"
          >+добавить новость</BtnPlain
        >
      </div>
    </section>

    <ModalRecords ref="modal" @update-course-records="updateCourseRecords" />

    <AlertMobile v-if="isMobile" @add-course-record="addCourseRecord" />
    <Alert v-else @add-course-record="addCourseRecord" />

    <BtnPlus v-if="isMobile && store.state.user && isEditorUser" @click="togglePostNew" />
  </div>
</template>

<script setup>
// Components
import ModalRecords from '@/components/education/ModalRecords'
import Alert from '@/components/Alert'
import AlertMobile from '@/components/AlertMobile'
import Post from '@/components/news/Post.vue'
import PostNew from '@/components/news/PostNew.vue'
import Course from '@/components/education/Course'
import Note from '@/components/Note'
import Subtitle from '@/components/Subtitle'
import Filter from '@/components/Filter'
import FilterMobile from '@/components/FilterMobile'
import Loader from '@/components/Loader'
import BtnPlain from '@/components/BtnPlain'
import BtnPlus from '@/components/BtnPlus'

// Composables
import fetchData from '@/mixins/fetchData'
import authorize from '@/mixins/authorize'
import isMobile from '@/mixins/isMobile'
import useFilter from '@/mixins/useFilter'
import useModalRecords from '@/mixins/useModalRecords'
import usePost from '@/mixins/usePost'

import { ref, computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const filterRef = ref(null)
const currentCurseId = ref(0)
const cities = ref('')

let { events, eventsLoaded, fetchAll } = fetchData()
let { courseId, toggleFilter } = useFilter()
const { isOpenPostNew, togglePostNew } = usePost()

const filterEvents = type => {
  if (type == 'news') {
    events.value = Object.values(eventsLoaded.value).filter(event => !Object.hasOwn(event, 'course'))
  } else if (type == 'education') {
    events.value = Object.values(eventsLoaded.value).filter(event => Object.hasOwn(event, 'course'))
  } else {
    events.value = eventsLoaded.value
  }
}

const addCourseRecord = (courseId, newRecord) => {
  let course = events.value.filter(event => event.id == courseId)[0]
  course.records.push(newRecord)
}

const updateCourseRecords = (recordId, newRecord) => {
  let courseIndex = courses.value.findIndex(course => course.id == currentCurseId.value)
  let recordIndex = courses.value[courseIndex].records.findIndex(record => record.id == recordId)

  courses.value[courseIndex].records[recordIndex] = newRecord
}

const isEditorUser = computed(() => {
  return store.state.user.positions.filter(position => position.id == 6).length > 0
})

authorize()
fetchAll()
</script>

<style scoped>
.news {
  background: #1d232a;
  display: flex;
  align-items: flex-start;
  padding: 24px 50px 0 50px;
}
.news__content {
  position: relative;
  margin-right: 30px;
  flex: 1;
}
.news__loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news__side {
  width: 329px;
  position: sticky;
  top: 24px;
  height: fit-content;
}
.news__sidebtn {
  width: 100%;
  margin-top: 30px;
}

@media only screen and (max-width: 600px) {
  .news {
    background: unset;
    display: unset;
    align-items: unset;
    padding: 0;
  }
  .news__content {
    margin-right: unset;
    flex: unset;
    padding: 0 16px 88px 16px;
  }
  .news__title {
    display: block;
  }
  .news__side {
    display: none;
  }
  .news__loader {
    margin-top: 150px;
  }
}
</style>
