<template>
  <svg width="33" height="33" viewBox="0 0 33 33" fill="white">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.8975 17.8157L21.9089 22.8271L23.1499 21.5861L18.1385 16.5747L23.6323 11.0809L22.3914 9.83984L16.8975 15.3337L11.3909 9.82715L10.1499 11.0682L15.6564 16.5747L10.6323 21.5988L11.8733 22.8398L16.8975 17.8157Z"
    />
  </svg>
</template>

<style scoped>
.closeSvg {
  cursor: pointer;
}
.closeSvg--white {
  fill: white;
  transition: 0.3s;
}
.closeSvg--white:hover {
  fill: rgba(255, 255, 255, 0.6);
  transition: 0.3s;
}
.closeSvg--gray {
  fill: rgba(255, 255, 255, 0.6);
  transition: 0.3s;
}
.closeSvg--gray:hover {
  fill: white;
  transition: 0.3s;
}
</style>
