<template>
  <div>
    <section class="news">
      <div class="news__content">
        <div class="post">
          Этот раздел еще не готов, загляните сюда позже - будет интересно!
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import authorize from '@/mixins/authorize'

authorize()
</script>

<style scoped>
.post {
  background: #343c42;
  border-radius: 12px;
  padding: 20px 16px;
  margin-bottom: 30px;
}
.news {
  background: #1d232a;
  display: flex;
  align-items: flex-start;
  padding: 24px 50px 0 50px;
}
.news__content {
  position: relative;
  margin-right: 30px;
  flex: 1;
}
.news__title {
  display: none;
  color: white;
  font-size: 18px;
  line-height: 24px;
  padding: 32px 0 40px 0;
}
.news__icon {
  margin-right: 10px;
}
@media only screen and (max-width: 600px) {
  .news {
    background: unset;
    display: unset;
    align-items: unset;
    padding: 0;
  }
  .news__content {
    margin-right: unset;
    flex: unset;
    padding: 0 16px 88px 16px;
  }
  .news__title {
    display: flex;
    align-items: center;
  }
  .news__side {
    display: none;
  }
}
</style>
