<template>
  <div v-if="isShown" class="note">
    <div class="note__header">
      {{ title }}
      <CloseSvg class="closeSvg closeSvg--gray" @click="isShown = false" />
    </div>
    <div class="note__imgbox">
      <img v-lazy="img" class="note__img" />
    </div>
    <div class="note__text">
      {{ text }}
    </div>
  </div>
</template>

<script setup>
// Svg
import CloseSvg from '@/components/svg/CloseSvg'

import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const isShown = ref(true)

let title = ''
let text = ''
let img = ''

if (router.currentRoute.value.path.split('/')[1] == 'team') {
  text = 'У нашей компании есть древо, где ты можешь найти любого сотрудники и узнать какую должность он занимает'
  title = 'Команда управления'
  img = require('@/assets/img/note2.webp')
}
if (router.currentRoute.value.path.split('/')[1] == 'education') {
  text = 'В этом разделе ты можешь узнать последние новости, записаться на курсы и узнать кто еще в них учавствует 😎'
  title = 'Обучение'
  img = require('@/assets/img/note1.webp')
}
if (router.currentRoute.value.path.split('/')[1] == 'news') {
  text = 'В этом разделе ты можешь узнать последние новости, записаться на курсы и узнать кто еще в них учавствует 😎'
  title = 'Новости'
  img = require('@/assets/img/note1.webp')
}
</script>

<style scoped>
.note {
  background: #15191c;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
}
.note__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 16px;
}
.note__imgbox {
  height: 97px;
  border-radius: 8px;
  margin-bottom: 16px;
}
.note__img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.note__text {
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}
</style>
