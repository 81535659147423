<template>
  <div class="subtitle">
    {{ title }}
    <SubtitleSvg />
  </div>
</template>

<script setup>
import SubtitleSvg from '@/components/svg/SubtitleSvg'

import { useRouter } from 'vue-router'

const router = useRouter()
let title = ''

if (
  router.currentRoute.value.path.split('/')[1] == 'news' ||
  router.currentRoute.value.path.split('/')[1] == 'education'
) {
  title = 'Фильтры'
}

if (
  router.currentRoute.value.path.split('/')[1] == 'settings' ||
  router.currentRoute.value.path.split('/')[1] == 'profile'
) {
  title = 'Разделы'
}
</script>

<style>
.subtitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 16px;
  color: #b9b9b9;
  margin-bottom: 34px;
}
</style>
