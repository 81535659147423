<template>
  <svg class="dotsSvg" width="25" height="25" viewBox="0 0 25 25">
    <circle cx="12.8921" cy="6.66699" r="1.5" />
    <circle cx="12.8921" cy="12.667" r="1.5" />
    <circle cx="12.8921" cy="18.667" r="1.5" />
  </svg>
</template>

<style scoped>
.dotsSvg {
  cursor: pointer;
  fill: rgba(255, 255, 255, 0.6);
  transition: 0.3s;
}
.dotsSvg:hover {
  fill: white;
  transition: 0.3s;
}
</style>
