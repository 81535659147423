import { ref } from 'vue'

const posts = ref(null)

export default () => {
  const getPosts = async () => {
    const data = await fetch('https://barberkuzma.ru:1414/posts/', {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    })
    const response = await data.json()
    posts.value = response
  }

  return {
    posts,
    getPosts
  }
}
