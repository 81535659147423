<template>
  <section class="news">
    <div class="news__content">
      <div v-if="workers">
        <Back :to="`/team/${$route.params.net}/${$route.params.city}`" />

        <div class="block">
          <div class="block__header">
            {{ adress.adress }}
          </div>

          <Worker v-for="worker in workers" :key="worker.id" :worker="worker" />
        </div>
      </div>
      <div v-else class="news__loader">
        <Loader />
      </div>
    </div>

    <div class="news__side">
      <Note :title="'Команда управления'" />
    </div>
  </section>
</template>

<script setup>
import Worker from '@/components/team/Worker'
import Loader from '@/components/Loader'
import Note from '@/components/Note'
import Back from '@/components/Back'
import authorize from '@/mixins/authorize'
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()
const adress = ref('')
const workers = ref('')

const getAdress = async () => {
  const data = await fetch(`https://barberkuzma.ru:1414/adresses/?url=${router.currentRoute.value.params.adress}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
  const response = await data.json()
  adress.value = response[0]
}

const getWorkers = async () => {
  const data = await fetch(`https://barberkuzma.ru:1414/users`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
  const response = await data.json()

  workers.value = response.filter(worker => {
    let hasProfile = worker.profile !== null && worker.profile.length !== ''
    let hasProfileAdress = null
    let matchesCurrentCity = null
    let isManager = worker.positions.filter(position => position.isManager).length > 0

    if (hasProfile) {
      hasProfileAdress = worker.profile.profileAdress !== null && worker.profile.profileAdress.length !== ''
    }
    if (hasProfileAdress) {
      matchesCurrentCity = worker.profile.profileAdress.url == router.currentRoute.value.params.adress
    }

    return hasProfile && hasProfileAdress && matchesCurrentCity && !isManager
  })
}

authorize()
getAdress()
getWorkers()
</script>

<style scoped>
.news {
  background: #1d232a;
  display: flex;
  align-items: flex-start;
  padding: 24px 50px 0 50px;
}
.news__content {
  position: relative;
  margin-right: 30px;
  flex: 1;
}
.news__loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news__side {
  width: 329px;
  position: sticky;
  top: 24px;
  height: fit-content;
}

.block {
  background: #343c42;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 30px;
}
.block--special {
  background: #2a3137;
  border-color: #ffe6a9;
}
.block:last-of-type {
  margin-bottom: 50px;
}
.block__header {
  display: flex;
  align-items: center;
  padding: 16px;
  letter-spacing: 0.02em;
  border-bottom: 1px solid #5d6368;
}
.block__header--noborder {
  border-bottom: none;
}

@media only screen and (max-width: 600px) {
  .news {
    background: unset;
    display: unset;
    align-items: unset;
    padding: 0;
  }
  .news__content {
    margin-right: unset;
    flex: unset;
    padding: 16px 16px 88px 16px;
  }
  .news__side {
    display: none;
  }
  .news__loader {
    margin-top: 150px;
  }

  .block {
    margin-bottom: 16px;
  }
  .block:last-of-type {
    margin-bottom: 0;
  }
}
</style>
