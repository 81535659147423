<template>
  <div class="newPost">
    <div class="newPost__header">
      Создание новости
      <CloseSvg class="closeSvg closeSvg--white" @click="togglePostNew" />
    </div>
    <div class="newPost__content">
      <CustomInput
        class="newPost__item newPost__item--full"
        :label="'Заголовок'"
        :placeholder="'Заголовок'"
        :value="title"
        @inputs="value => (title = value)"
      />
      <CustomTextarea
        class="newPost__item"
        :label="'Содержание'"
        :placeholder="'Содержание'"
        :value="content"
        @inputs="value => (content = value)"
      />
      <CustomTextarea
        class="newPost__item"
        :label="'Текст предспросмотра'"
        :placeholder="'Текст предспросмотра'"
        :value="previewText"
        @inputs="value => (previewText = value)"
      />
      <CustomInput
        class="newPost__item newPost__item--full"
        :label="'Дата публикации'"
        :value="date"
        :type="'date'"
        @inputs="value => (date = value)"
      />
      <CustomSelectbox
        class="newPost__item newPost__item--full"
        :label="'Выберите сеть'"
        :key-name="'name'"
        :value="0"
        :name="'barbershop'"
        :options="barbershops"
        @inputs="value => barbershopsHandler(value)"
      />
      <CustomSelectboxMulti
        v-if="barbershop != '' && cities != ''"
        class="newPost__item newPost__item--full"
        :label="'Выберите город'"
        :key-name="'name'"
        :value="0"
        :name="'city'"
        :options="cities"
        @update-items="value => (selectedCities = [...value])"
      />

      <img v-if="selectedImgSrc != ''" class="newPost__image" :src="selectedImgSrc" />

      <div class="newPost__footer">
        <div class="newPost__load">
          <AttachmentSvg class="newPost__icon" />
          <div v-if="selectedImgSrc == ''">Загрузить обложкку</div>
          <div v-else>Изменить обложкку</div>
          <input class="newPost__files" type="file" @change="selectImage" />
        </div>

        <Button :disabled="title == ''" @click="create" />
      </div>
    </div>
  </div>
</template>

<script setup>
// Svg
import CloseSvg from '@/components/svg/CloseSvg'
import AttachmentSvg from '@/components/svg/AttachmentSvg'

// Components
import CustomInput from '@/components/CustomInput'
import CustomTextarea from '@/components/CustomTextarea'
import CustomSelectbox from '@/components/CustomSelectbox'
import CustomSelectboxMulti from '@/components/CustomSelectboxMulti'
import Button from '@/components/Button'

// Helpers
import useAlert from '@/mixins/useAlert'
import usePost from '@/mixins/usePost'

import { ref } from 'vue'

const title = ref('')
const content = ref('')
const date = ref('')
const previewText = ref('')
const selectedImgSrc = ref('')
const imgFile = ref('')
const barbershops = ref('')
const cities = ref('')
const barbershop = ref('')
const selectedCities = ref('')
const { alertTitle, alertTitleSuccess, alertSubtitle, alertSubtitleSuccess, toggleAlert, task } = useAlert()
const {
  postTitle,
  postContent,
  postDate,
  postPreviewText,
  postSelectedImgSrc,
  postImgFile,
  postBarbershop,
  postCities,
  togglePostNew,
  createPost
} = usePost()

const selectImage = e => {
  imgFile.value = e.target.files
  selectedImgSrc.value = URL.createObjectURL(e.target.files[0])
}

const create = () => {
  postTitle.value = title.value
  postContent.value = content.value
  postDate.value = date.value
  postPreviewText.value = previewText.value
  postSelectedImgSrc.value = selectedImgSrc.value
  postImgFile.value = imgFile.value
  postBarbershop.value = barbershop.value
  postCities.value = selectedCities.value

  alertTitle.value = 'Создать новую запись?'
  alertTitleSuccess.value = 'Запись создана'
  alertSubtitle.value = 'Будет создана новая запись в разделе "Новости"'
  alertSubtitleSuccess.value = 'Запись создана'
  task.value = createPost
  toggleAlert()
}

const getBarbershopsData = async () => {
  const data = await fetch(`https://barberkuzma.ru:1414/barbershops/`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
  const response = await data.json()
  barbershops.value = response
}

const getCitiesData = async () => {
  const data = await fetch(`https://barberkuzma.ru:1414/cities?barbershops.id=${barbershop.value.id}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
  const response = await data.json()
  cities.value = response
}

const barbershopsHandler = value => {
  barbershop.value = value
  selectedCities.value = null
  if (value !== null) getCitiesData()
}

getBarbershopsData()
</script>

<style scoped>
.newPost {
  background: #343c42;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  margin-bottom: 30px;
}
.newPost__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  border-bottom: 1px solid #5d6368;
  letter-spacing: 0.02em;
}
.newPost__content {
  padding: 16px 40px;
  display: flex;
  flex-wrap: wrap;
}
.newPost__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-top: 16px;
}
.newPost__load {
  position: relative;
  color: #71777b;
  display: flex;
  align-items: center;
}
.newPost__files {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  font-size: 0;
  text-indent: -100px px;
  cursor: pointer;
}
.newPost__icon {
  margin-right: 8px;
}
.newPost__item {
  margin-bottom: 24px;
}
.newPost__item--full {
  width: 100%;
}
.newPost__item--half {
  width: 50%;
}
.newPost__image {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .newPost__content {
    padding: 16px;
  }
  .newPost__footer {
    display: block;
    margin-top: 0;
  }
  .newPost__image {
    margin-bottom: 16px;
  }
  .newPost__load {
    margin-bottom: 16px;
  }
}
</style>
