<template>
  <svg width="37" height="36" viewBox="0 0 37 36" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.5798 0.0510733C13.8115 0.499344 11.4899 1.31045 8.72842 3.14377C7.2922 4.09723 4.96535 6.42493 4.01197 7.86194C0.371729 13.3487 -0.133191 19.9755 2.64843 25.7599C5.17088 31.0051 9.80552 34.5647 15.6266 35.7272C17.5442 36.1102 20.413 36.0876 22.3802 35.674C28.1032 34.4705 32.6344 30.9212 35.1455 25.675C37.5013 20.7529 37.4419 14.8988 34.9865 10.0041C34.0564 8.15016 33.1564 6.92065 31.5598 5.32279C29.0631 2.82422 26.2649 1.24857 22.9646 0.482888C21.2976 0.0961597 18.8506 -0.100079 17.5798 0.0510733ZM26.7821 9.55189C28.4151 9.67655 28.9691 9.87505 29.6862 10.5924C30.1834 11.0896 30.5088 11.8448 30.7303 13.0154C30.934 14.0914 30.934 21.7065 30.7303 22.7825C30.2794 25.1652 29.4593 25.9852 27.2482 26.2644C25.6791 26.4625 11.91 26.4605 10.4399 26.262C7.67103 25.888 6.99303 24.9448 6.69691 21.055C6.55121 19.1419 6.61848 14.8114 6.81612 13.3754C7.12904 11.1017 7.98585 9.99024 9.66681 9.67723C10.0526 9.60543 11.3824 9.5023 12.6218 9.44808C15.3279 9.32984 24.779 9.39916 26.7821 9.55189ZM16.2839 17.6646V21.0473L18.3404 19.9752C19.4713 19.3856 20.9164 18.6402 21.5517 18.3189C22.1869 17.9977 22.7067 17.707 22.7067 17.673C22.7067 17.6389 22.2883 17.3991 21.7771 17.14C21.2659 16.8809 19.8206 16.1318 18.5657 15.4753L16.2839 14.2818V17.6646Z"
      fill="white"
      fill-
    />
  </svg>
</template>
