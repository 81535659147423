<template>
  <svg width="24" height="24" viewBox="0 0 28 28">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.6738 12.5C15.6068 12.5 17.1738 10.933 17.1738 9C17.1738 7.067 15.6068 5.5 13.6738 5.5C11.7408 5.5 10.1738 7.067 10.1738 9C10.1738 10.933 11.7408 12.5 13.6738 12.5ZM13.6738 15C16.9875 15 19.6738 12.3137 19.6738 9C19.6738 5.68629 16.9875 3 13.6738 3C10.3601 3 7.67383 5.68629 7.67383 9C7.67383 12.3137 10.3601 15 13.6738 15Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.197 25C21.7411 23.7227 21.0757 22.5698 20.2438 21.6042C19.4122 20.639 18.4315 19.8808 17.3612 19.3663C16.2914 18.8519 15.1496 18.5893 14 18.5893C12.8504 18.5893 11.7086 18.8519 10.6388 19.3663C9.56854 19.8808 8.58782 20.639 7.75618 21.6042C6.92433 22.5698 6.25887 23.7227 5.80303 25L3.5 24.0895C4.06118 22.517 4.8864 21.0805 5.93377 19.8649C6.98136 18.649 8.2316 17.6769 9.61666 17.011C11.0021 16.3449 12.492 16 14 16C15.508 16 16.9979 16.3449 18.3833 17.011C19.7684 17.6769 21.0186 18.649 22.0662 19.8649C23.1136 21.0805 23.9388 22.517 24.5 24.0895L22.197 25Z"
      fill="white"
    />
  </svg>
</template>
