<template>
  <div>
    <PostEdit v-if="isEditing" :post="props.post" :barbershops="barbershops" @toggle-editing="toggleEditing" />
    <PostShow v-else :post="props.post" :is-editor-user="isEditorUser" @toggle-editing="toggleEditing" />
  </div>
</template>

<script setup>
// Components
import PostShow from '@/components/news/PostShow'
import PostEdit from '@/components/news/PostEdit'

import { ref } from 'vue'

const props = defineProps({
  post: {
    type: Object,
    default () {
      return {}
    }
  },
  barbershops: {
    type: Object,
    default () {
      return {}
    }
  },
  isEditorUser: {
    type: Boolean,
    default () {
      return false
    }
  }
})
const isEditing = ref(false)

const toggleEditing = () => {
  if (isEditing.value) {
    isEditing.value = false
  } else {
    isEditing.value = true
  }
}
</script>
