<template>
  <section v-if="isOpenAlert" class="alert" @click.self="closeModal">
    <div class="alert__content">
      <div class="alert__header">
        <div v-if="state == ''" class="alert__title">{{ alertTitle }}</div>
        <div v-else-if="state == 'success'" class="alert__title">{{ alertTitleSuccess }}</div>
        <div v-else-if="state == 'failure'" class="alert__title">Ошибка! 😮</div>
        <CloseSvg class="closeSvg closeSvg--white alert__close" @click="closeModal" />
      </div>

      <div class="alert__body">
        <div v-if="state == ''" class="alert__text">«{{ alertSubtitle }}»</div>

        <div v-else-if="state == 'success'" class="alert__text">{{ alertSubtitleSuccess }}</div>

        <div v-else-if="state == 'failure'" class="alert__text">Возможно какие-то неполадки. Попробуйте позже</div>

        <div v-if="isLoading" class="alert__loaderbox">
          <Loader />
        </div>

        <div v-if="state == ''">
          <button class="button button--filled alert__button" @click="handler">
            Все верно
          </button>
          <button class="button button--plain" @click="closeModal">Отмена</button>
        </div>

        <CloseModalSvg class="alert__closeIcon" @click="closeModal" />
      </div>
    </div>
  </section>
</template>

<script setup>
// Svg
import CloseModalSvg from '@/components/svg/CloseModalSvg'

// Components
import CloseSvg from '@/components/svg/CloseSvg'
import Loader from '@/components/Loader'

// Composables
import useAlert from '@/mixins/useAlert'

import { ref } from 'vue'

const {
  isOpenAlert,
  alertEventId,
  alertTitle,
  alertTitleSuccess,
  alertSubtitle,
  alertSubtitleSuccess,
  toggleAlert,
  task
} = useAlert()

const state = ref('')
const isLoading = ref(false)

const handler = async () => {
  isLoading.value = true

  if (await task.value(alertEventId)) {
    isLoading.value = false
    state.value = 'success'
  } else {
    isLoading.value = false
    state.value = 'failure'
  }
}

const closeModal = () => {
  toggleAlert()
  state.value = ''
}
</script>

<style scoped>
.alert {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.alert__content {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #2a3137;
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.55);
  border-radius: 20px 20px 0px 0px;
  overflow: hidden;
}
.alert__header {
  display: flex;
  align-items: center;
  padding: 20px 16px;
  border-bottom: 1px solid rgba(126, 139, 158, 0.5);
}
.alert__title {
  font-weight: 700;
  color: #ffda7e;
  flex: 1;
}
.alert__body {
  padding: 16px;
}
.alert__text {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 18px;
}
.alert__button {
  margin-bottom: 16px;
}
.alert__loaderbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #343c42;
}
.alert__closeIcon {
  display: block;
  margin: 40px auto;
}
</style>
