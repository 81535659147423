<template>
  <div>
    <CourseEdit v-if="isEditing" :course="props.course" @toggle-editing="toggleEditing" />
    <CourseShow v-else :course="props.course" :is-editor-user="isEditorUser" @toggle-editing="toggleEditing" />
  </div>
</template>

<script setup>
// Components
import CourseShow from '@/components/education/CourseShow'
import CourseEdit from '@/components/education/CourseEdit'

import { ref } from 'vue'

const props = defineProps({
  course: {
    type: Object,
    default () {
      return {}
    }
  },
  isEditorUser: {
    type: Boolean,
    default () {
      return false
    }
  }
})

const isEditing = ref(false)

const toggleEditing = () => {
  if (isEditing.value) {
    isEditing.value = false
  } else {
    isEditing.value = true
  }
}
</script>
