<template>
  <svg width="24" height="22" viewBox="0 0 24 22">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.7983 10.693L19.2197 7.60783C19.0905 7.48488 18.9153 7.4158 18.7327 7.4158C18.55 7.4158 18.3748 7.48488 18.2456 7.60783V9.84048H13.226V5.00368H15.5457C15.6731 4.87954 15.7444 4.71286 15.7444 4.53929C15.7444 4.36572 15.6731 4.19904 15.5457 4.0749L12.4782 1.69223C12.4152 1.63135 12.3402 1.583 12.2574 1.55C12.1746 1.517 12.0858 1.5 11.9961 1.5C11.9064 1.5 11.8175 1.517 11.7348 1.55C11.652 1.583 11.577 1.63135 11.514 1.69223L8.47272 4.0749C8.34412 4.19844 8.27197 4.36535 8.27197 4.53929C8.27197 4.71323 8.34412 4.88014 8.47272 5.00368H10.7512V9.84048H5.71781V7.62688C5.58861 7.50393 5.41341 7.43486 5.23073 7.43486C5.04806 7.43486 4.87286 7.50393 4.74365 7.62688C4.74365 7.62688 2 10.4894 2 11.0777C2 11.5873 4.74365 14.2688 4.74365 14.2688C4.80755 14.3299 4.88346 14.3783 4.96705 14.4113C5.05063 14.4444 5.14024 14.4614 5.23073 14.4614C5.32123 14.4614 5.41084 14.4444 5.49442 14.4113C5.57801 14.3783 5.65392 14.3299 5.71781 14.2688V12.166H10.7512V16.9849H8.45271C8.32747 17.1053 8.25722 17.2679 8.25722 17.4374C8.25722 17.6069 8.32747 17.7695 8.45271 17.8899C8.45271 17.8899 11.3689 20.5 11.9867 20.5C12.5244 20.5 15.5457 17.8899 15.5457 17.8899C15.6709 17.7695 15.7412 17.6069 15.7412 17.4374C15.7412 17.2679 15.6709 17.1053 15.5457 16.9849H13.226V12.166H18.2456V14.3236C18.3748 14.4466 18.55 14.5156 18.7327 14.5156C18.9153 14.5156 19.0905 14.4466 19.2197 14.3236L21.7983 11.6206C21.9275 11.4976 22 11.3308 22 11.1568C22 10.9829 21.9275 10.8161 21.7983 10.693Z"
      fill="white"
    />
  </svg>
</template>
