import { ref } from 'vue'
import fetchData from '@/mixins/fetchData'
import store from '@/store'

const isOpenCourseNew = ref(false)
const courseId = ref('')
const courseTitle = ref('')
const coursePriceStart = ref('')
const coursePriceFull = ref('')
const courseViews = ref('')
const courseDate = ref('')
const courseDescription = ref('')
const courseExpectation = ref('')
const courseProgram = ref('')
const courseOutcome = ref('')
const courseCategory = ref('')
const courseNickname = ref('')
const courseDurationMonths = ref('')
const courseDurationDays = ref('')
const courseAffiliation = ref('')
const courseImgFile = ref('')
const { fetchAll } = fetchData()

export default () => {
  const toggleCourseNew = () => {
    if (isOpenCourseNew.value) {
      isOpenCourseNew.value = false
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      isOpenCourseNew.value = true
    }
  }

  const formData = () => {
    const formData = new FormData()
    const data = {}

    data.course = courseTitle.value
    if (coursePriceStart.value) data.priceStart = coursePriceStart.value
    if (coursePriceFull.value) data.priceFull = coursePriceFull.value
    if (courseViews.value) data.views = courseViews.value
    if (courseDescription.value) data.description = courseDescription.value
    if (courseExpectation.value) data.expectation = courseExpectation.value
    if (courseProgram.value) data.program = courseProgram.value
    if (courseOutcome.value) data.outcome = courseOutcome.value
    if (courseCategory.value) data.type = courseCategory.value
    if (courseNickname.value) data.nickname = courseNickname.value
    if (courseDurationMonths.value) data.durationMonths = courseDurationMonths.value
    if (courseDurationDays.value) data.durationDays = courseDurationDays.value
    if (courseAffiliation.value) data.category = courseAffiliation.value
    if (courseDate.value == '') {
      data.date = '2022-09-01'
    } else {
      data.date = courseDate.value
    }

    formData.append('data', JSON.stringify(data))
    formData.append('files.image', courseImgFile.value[0])

    return formData
  }

  const createCourse = async () => {
    try {
      const data = await fetch(`https://barberkuzma.ru:1414/courses`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: formData()
      })

      await data.json()

      return true
    } catch (error) {
      return false
    } finally {
      toggleCourseNew()
      fetchAll()
    }
  }

  const removeCourse = async () => {
    try {
      const data = await fetch(`https://barberkuzma.ru:1414/courses/${courseId.value}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      })

      await data.json()

      return true
    } catch (error) {
      return false
    } finally {
      fetchAll()
    }
  }

  const updateCourse = async () => {
    try {
      const data = await fetch(`https://barberkuzma.ru:1414/courses/${courseId.value}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: formData()
      })

      await data.json()

      return true
    } catch (error) {
      return false
    } finally {
      fetchAll()
    }
  }

  const createRecord = async () => {
    try {
      const data = await fetch(`https://barberkuzma.ru:1414/course-records`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          confirmed: false,
          course: courseId.value,
          users_permissions_user: store.state.user.id
        })
      })

      await data.json()

      return true
    } catch {
      return false
    } finally {
      fetchAll()
    }
  }

  return {
    isOpenCourseNew,
    courseId,
    courseTitle,
    coursePriceStart,
    coursePriceFull,
    courseViews,
    courseDate,
    courseDescription,
    courseExpectation,
    courseProgram,
    courseOutcome,
    courseCategory,
    courseNickname,
    courseDurationMonths,
    courseDurationDays,
    courseAffiliation,
    courseImgFile,
    toggleCourseNew,
    createCourse,
    removeCourse,
    updateCourse,
    createRecord
  }
}
