<template>
  <section class="profile">
    <div class="profile__header">О себе</div>

    <div class="profile__body">
      <div class="profile__inputs">
        <CustomTextarea
          :value="about.aboutAbout"
          :placeholder="'Опишите себя в 3-4 предложениях'"
          @inputs="value => (about.aboutAbout = value)"
        />
      </div>

      <Button class="profile__button" :loading="loading" :success="success" @click="updateServerData" />
    </div>
  </section>
</template>

<script setup>
import CustomTextarea from '@/components/CustomTextarea'
import Button from '@/components/Button'
import buttonMixin from '@/mixins/buttonMixin'

import { useStore } from 'vuex'

const store = useStore()
const about = store.state.user.about
let { loading, success, buttonLoad, buttonSuccess } = buttonMixin()

const updateServerData = async () => {
  buttonLoad()

  await fetch(`https://barberkuzma.ru:1414/users/${store.state.user.id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ about: about })
  })

  buttonSuccess()
}
</script>
