<template>
  <section id="socials" class="profile">
    <div class="profile__header">Социальные сети</div>

    <div class="profile__body">
      <div>
        <Social
          :value="store.state.user.social"
          :label="'Instagram'"
          :key-name="'socialInstagram'"
          @click="updateServerData"
        />
        <Social
          :value="store.state.user.social"
          :label="'WhatsApp'"
          :key-name="'socialWhatsapp'"
          @click="updateServerData"
        />
        <Social
          :value="store.state.user.social"
          :label="'Telegram'"
          :key-name="'socialTelegram'"
          @click="updateServerData"
        />
        <Social
          :value="store.state.user.social"
          :label="'YouTube'"
          :key-name="'socialYoutube'"
          @click="updateServerData"
        />
        <Social
          :value="store.state.user.social"
          :label="'TikTok'"
          :key-name="'socialTiktok'"
          @click="updateServerData"
        />
        <Social
          :value="store.state.user.social"
          :label="'VKontakte'"
          :key-name="'socialVkontakte'"
          @click="updateServerData"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import Social from '@/components/Social'

import { ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const updateServerData = async updatedSocial => {
  await fetch(`https://barberkuzma.ru:1414/users/${store.state.user.id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ social: updatedSocial })
  })
}
</script>
