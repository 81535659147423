import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Login.vue'
import News from '../views/News.vue'
import Education from '../views/Education.vue'
import Team from '../views/Team.vue'
import Competition from '../views/Competition.vue'
import Career from '../views/Career.vue'
import Settings from '../views/Settings.vue'
import Support from '../views/Support.vue'
import Menu from '../views/Menu.vue'
import Profile from '../views/Profile.vue'

import City from '../views/team/City.vue'
import Adress from '../views/team/Adress.vue'
import Net from '../views/team/Net.vue'

const routes = [
  {
    path: '/',
    redirect: localStorage.getItem('token') != null ? '/news' : '/login'
  },
  {
    path: '/login',
    name: 'Login',
    redirect: localStorage.getItem('token') != null ? '/news' : false,
    component: Login,
    meta: {
      title: 'Авторизация | BarberTeam'
    }
  },
  {
    path: '/news',
    name: 'News',
    component: News,
    meta: {
      title: 'Новости | BarberTeam'
    }
  },
  {
    path: '/education',
    name: 'Education',
    component: Education,
    meta: {
      title: 'Обучение | BarberTeam'
    }
  },
  {
    path: '/team',
    name: 'Team',
    component: Team,
    meta: {
      title: 'Команда управления | BarberTeam'
    }
  },
  {
    path: '/team/:net',
    name: 'Net',
    component: Net,
    params: true,
    props: true,
    meta: {
      title: 'Команда управления | BarberTeam'
    }
  },
  {
    path: '/team/:net/:city',
    name: 'City',
    component: City,
    params: true,
    props: true,
    meta: {
      title: 'Команда управления | BarberTeam'
    }
  },
  {
    path: '/team/:net/:city/:adress',
    name: 'Adress',
    component: Adress,
    meta: {
      title: 'Команда управления | BarberTeam'
    }
  },
  {
    path: '/competition',
    name: 'Competition',
    component: Competition,
    meta: {
      title: 'Конкурсы | BarberTeam'
    }
  },
  {
    path: '/career',
    name: 'Career',
    component: Career,
    meta: {
      title: 'Карьера | BarberTeam'
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      title: 'Настройки | BarberTeam'
    }
  },
  {
    path: '/support',
    name: 'Support',
    component: Support,
    meta: {
      title: 'Поддержка | BarberTeam'
    }
  },
  {
    path: '/menu',
    name: 'Menu',
    component: Menu,
    meta: {
      title: 'Меню | BarberTeam'
    }
  },
  {
    path: '/profile/:id',
    name: 'Profile',
    component: Profile,
    meta: {
      title: 'Профиль | BarberTeam'
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`
  next()
})

export default router
