<template>
  <div class="newPost">
    <div class="newPost__header">
      Редактирование новости
      <CloseSvg class="closeSvg closeSvg--white" @click="emit('toggleEditing')" />
    </div>
    <div class="newPost__content">
      <CustomInput
        class="newPost__item newPost__item--full"
        :label="'Заголовок'"
        :placeholder="'Заголовок'"
        :value="title"
        @inputs="value => (title = value)"
      />
      <CustomTextarea
        class="newPost__item"
        :label="'Содержание'"
        :placeholder="'Содержание'"
        :value="content"
        @inputs="value => (content = value)"
      />
      <CustomTextarea
        class="newPost__item"
        :label="'Текст предспросмотра'"
        :placeholder="'Текст предспросмотра'"
        :value="previewText"
        @inputs="value => (previewText = value)"
      />
      <CustomInput
        class="newPost__item newPost__item--full"
        :label="'Дата публикации'"
        :value="date"
        :type="'date'"
        @inputs="value => (date = value)"
      />

      <CustomSelectbox
        class="newPost__item newPost__item--full"
        :label="'Выберите сеть'"
        :key-name="'name'"
        :value="barbershop"
        :name="'barbershop'"
        :options="barbershops"
        @inputs="value => barbershopsHandler(value)"
      />

      <CustomSelectboxMulti
        v-if="barbershop != '' && cities != ''"
        class="newPost__item newPost__item--full"
        :label="'Выберите город'"
        :key-name="'name'"
        :value="selectedCities"
        :name="'city'"
        :options="cities"
        @update-items="value => (selectedCities = [...value])"
      />

      <img v-if="selectedImgSrc != ''" class="newPost__image" :src="selectedImgSrc" />

      <div class="newPost__footer">
        <div class="newPost__load">
          <AttachmentSvg class="newPost__icon" />
          <div v-if="selectedImgSrc == ''">Загрузить обложкку</div>
          <div v-else>Изменить обложкку</div>
          <input class="newPost__files" type="file" @change="selectImage" />
        </div>

        <!-- <div v-if="selectedImgSrc != ''" class="newPost__load" @click="selectedImgSrc = ''">
          <BinSvg class="newPost__icon" />
          <div>Удалить обложкку</div>
        </div> -->

        <Button :disabled="title == ''" :loading="loading" :success="success" @click="update" />
      </div>
    </div>
  </div>
</template>

<script setup>
// Svg
import CloseSvg from '@/components/svg/CloseSvg'
import AttachmentSvg from '@/components/svg/AttachmentSvg'
import BinSvg from '@/components/svg/BinSvg'

// Components
import CustomInput from '@/components/CustomInput'
import CustomTextarea from '@/components/CustomTextarea'
import Button from '@/components/Button'
import CustomSelectbox from '@/components/CustomSelectbox'
import CustomSelectboxMulti from '@/components/CustomSelectboxMulti'

// Composables
import usePost from '@/mixins/usePost'
import buttonMixin from '@/mixins/buttonMixin'

import { ref, toRef } from 'vue'

const emit = defineEmits(['toggleEditing'])
const props = defineProps({
  post: {
    type: Object,
    default () {
      return {}
    }
  }
})

const postRef = toRef(props, 'post')

const { loading, success, buttonLoad, buttonSuccess } = buttonMixin()
const propsRef = toRef(props, 'post')
const title = ref('')
const content = ref('')
const previewText = ref('')
const date = ref('')
const barbershops = ref('')
const cities = ref('')
const barbershop = ref(postRef.value.barbershop)
const selectedCities = ref(postRef.value.cities)
const selectedImgSrc = ref('')
const imgFile = ref('')
const {
  postId,
  postTitle,
  postContent,
  postDate,
  postPreviewText,
  postSelectedImgSrc,
  postImgFile,
  postBarbershop,
  postCities,
  updatePost
} = usePost()

title.value = propsRef.value.title
content.value = propsRef.value.content
date.value = propsRef.value.date
previewText.value = propsRef.value.previewText
if (propsRef.value.previewImage) selectedImgSrc.value = `https://barberkuzma.ru:1414${propsRef.value.previewImage.url}`

const selectImage = e => {
  imgFile.value = e.target.files
  selectedImgSrc.value = URL.createObjectURL(e.target.files[0])
}

const update = async () => {
  postId.value = propsRef.value.id
  postTitle.value = title.value
  postContent.value = content.value
  postDate.value = date.value
  postPreviewText.value = previewText.value
  postSelectedImgSrc.value = selectedImgSrc.value
  postImgFile.value = imgFile.value
  postBarbershop.value = barbershop.value
  postCities.value = selectedCities.value

  buttonLoad()
  await updatePost()
  buttonSuccess()
  emit('toggleEditing')
}

const getBarbershopsData = async () => {
  const data = await fetch(`https://barberkuzma.ru:1414/barbershops/`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
  const response = await data.json()
  barbershops.value = response
}

const getCitiesData = async () => {
  const data = await fetch(`https://barberkuzma.ru:1414/cities?barbershops.id=${barbershop.value.id}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
  const response = await data.json()
  cities.value = response
}

const barbershopsHandler = value => {
  barbershop.value = value
  selectedCities.value = null
  if (value !== null) getCitiesData()
}

getBarbershopsData()
if (barbershop.value !== null) getCitiesData()
</script>

<style scoped>
.newPost {
  background: #343c42;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  margin-bottom: 30px;
}
.newPost__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  border-bottom: 1px solid #5d6368;
  letter-spacing: 0.02em;
}
.newPost__content {
  padding: 16px 40px;
  display: flex;
  flex-wrap: wrap;
}
.newPost__footer {
  display: flex;
  align-items: center;
  flex: 1;
  margin-top: 16px;
}
.newPost__load {
  position: relative;
  color: #71777b;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 18px;
}
.newPost__load:last-of-type {
  margin-right: auto;
}
.newPost__files {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  font-size: 0;
  text-indent: -100px px;
  cursor: pointer;
}
.newPost__icon {
  margin-right: 8px;
}
.newPost__item {
  margin-bottom: 24px;
}
.newPost__item--full {
  width: 100%;
}
.newPost__item--half {
  width: 50%;
}
.newPost__image {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .newPost__content {
    padding: 16px;
  }
  .newPost__footer {
    display: block;
    margin-top: 0;
  }
  .newPost__image {
    margin-bottom: 16px;
  }
  .newPost__load {
    margin-bottom: 16px;
  }
}
</style>
