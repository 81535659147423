<template>
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none">
    <g opacity="0.4">
      <path
        d="M20.8233 8.13115C18.7331 6.17894 15.3292 6.17894 13.2412 8.13115L7.4498 13.536C7.41208 13.5712 7.39211 13.6189 7.39211 13.6686C7.39211 13.7183 7.41208 13.766 7.4498 13.8012L8.26859 14.566C8.30601 14.6008 8.35668 14.6203 8.40949 14.6203C8.4623 14.6203 8.51297 14.6008 8.55039 14.566L14.3418 9.16113C15.0607 8.48967 16.0171 8.12078 17.0334 8.12078C18.0496 8.12078 19.006 8.48967 19.7227 9.16113C20.4416 9.83259 20.8366 10.7258 20.8366 11.6729C20.8366 12.622 20.4416 13.5132 19.7227 14.1846L13.8204 19.6952L12.864 20.5884C11.9698 21.4235 10.5164 21.4235 9.62214 20.5884C9.18944 20.1843 8.95202 19.6475 8.95202 19.0755C8.95202 18.5035 9.18944 17.9668 9.62214 17.5627L15.4779 12.0957C15.6266 11.9589 15.8218 11.8822 16.0304 11.8822H16.0326C16.2412 11.8822 16.4343 11.9589 16.5807 12.0957C16.7294 12.2345 16.8093 12.4169 16.8093 12.6117C16.8093 12.8044 16.7272 12.9868 16.5807 13.1236L11.7945 17.5896C11.7567 17.6248 11.7368 17.6725 11.7368 17.7222C11.7368 17.772 11.7567 17.8196 11.7945 17.8549L12.6133 18.6196C12.6507 18.6544 12.7013 18.6739 12.7542 18.6739C12.807 18.6739 12.8576 18.6544 12.8951 18.6196L17.6791 14.1515C18.1206 13.7391 18.3625 13.192 18.3625 12.6096C18.3625 12.0273 18.1184 11.4781 17.6791 11.0677C16.7671 10.216 15.2848 10.2181 14.3729 11.0677L13.8048 11.6004L8.51933 16.5347C8.16059 16.8678 7.87623 17.2641 7.68274 17.7006C7.48924 18.1371 7.39045 18.6052 7.39211 19.0776C7.39211 20.0371 7.79374 20.9386 8.51933 21.6163C9.27154 22.3168 10.2568 22.667 11.242 22.667C12.2272 22.667 13.2124 22.3168 13.9624 21.6163L20.8233 15.2126C21.8329 14.2675 22.3921 13.0096 22.3921 11.6729C22.3943 10.3341 21.8351 9.07616 20.8233 8.13115Z"
        fill="white"
      />
    </g>
  </svg>
</template>
