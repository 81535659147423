<template>
  <section v-if="isOpen" class="modal" @click.self="toggle">
    <div class="modal__content">
      <div class="modal__title">Отлично! 😎</div>
      <div class="modal__text">
        Заявка отправлена на модерацию, ждите подтверждение
      </div>
      <svg class="modal__close" width="33" height="32" viewBox="0 0 33 32" fill="none" @click="toggle">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.7437 16.9886L21.7551 22L22.9961 20.7589L17.9847 15.7476L23.4785 10.2537L22.2375 9.0127L16.7437 14.5066L11.2371 9L9.99609 10.2411L15.5026 15.7476L10.4785 20.7716L11.7195 22.0127L16.7437 16.9886Z"
          fill="white"
        />
      </svg>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'

const isOpen = ref(false)

const toggle = () => {
  isOpen.value ? (isOpen.value = false) : (isOpen.value = true)
}
</script>

<style scoped>
.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.modal__content {
  position: relative;
  width: 706px;
  padding: 77px 0;
  background: #343c42;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.45);
  border-radius: 8px;
  cursor: default;
}
.modal__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 8px;
}
.modal__text {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  width: 392px;
  margin: 0 auto;
}
.modal__close {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}
</style>
