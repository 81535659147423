<template>
  <div v-if="isOpenAlert" class="alert" @click.self="closeModal">
    <div class="alert__content">
      <div v-if="state == ''">
        <div class="alert__title">{{ alertTitle }}</div>
        <div class="alert__text">{{ alertSubtitle }}</div>
      </div>
      <div v-else-if="state == 'success'">
        <div class="alert__title">{{ alertTitleSuccess }}</div>
        <div class="alert__text">{{ alertSubtitleSuccess }}</div>
      </div>
      <div v-else-if="state == 'failure'">
        <div class="alert__title">Ошибка! 😮</div>
        <div class="alert__text">
          Возможно какие-то неполадки. Попробуйте позже
        </div>
      </div>

      <div v-if="isLoading" class="alert__loaderbox">
        <Loader />
      </div>

      <div v-if="state == ''" class="alert__buttons">
        <button class="button button--filled alert__button" @click="handler">
          Все верно
        </button>
        <button class="button button--plain" @click="closeModal">Отмена</button>
      </div>

      <CloseSvg class="closeSvg closeSvg--white alert__close" @click="closeModal" />
    </div>
  </div>
</template>

<script setup>
import useAlert from '@/mixins/useAlert'

import CloseSvg from '@/components/svg/CloseSvg'
import Loader from '@/components/Loader'

import { ref } from 'vue'

const {
  isOpenAlert,
  alertTitle,
  alertTitleSuccess,
  alertSubtitle,
  alertSubtitleSuccess,
  toggleAlert,
  task
} = useAlert()

const state = ref('')
const isLoading = ref(false)

const handler = async () => {
  isLoading.value = true

  if (await task.value()) {
    isLoading.value = false
    state.value = 'success'
  } else {
    isLoading.value = false
    state.value = 'failure'
  }
}

const closeModal = () => {
  toggleAlert()
  state.value = ''
}
</script>

<style scoped>
.alert {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.alert__content {
  position: relative;
  width: 706px;
  padding: 47px 0;
  background: #343c42;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.45);
  border-radius: 8px;
  cursor: default;
}
.alert__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 8px;
}
.alert__text {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  width: 392px;
  margin: 0 auto;
}
.alert__close {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
  transition: 0.3s;
}
.alert__close:hover {
  fill: #ffda7e;
  transition: 0.3s;
}
.alert__buttons {
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  margin-top: 24px;
}
.alert__button {
  margin-right: 16px;
}
.alert__loaderbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #343c42;
}

@media only screen and (max-width: 600px) {
  .alert {
    overflow: scroll;
  }
  .alert__content {
    position: absolute;
    left: 0;
    top: calc(100% - 340px);
    width: 100%;
    border-right: none;
    border-left: none;
    border-bottom: none;
    border-radius: 20px 20px 0px 0px;
  }
  .alert__header {
    justify-content: space-between;
    padding: 16px;
    color: #ffda7e;
  }
  .alert__headbox {
    display: block;
    flex: unset;
  }
  .alert__title {
    padding-right: 0;
    margin-bottom: 8px;
  }
  .alert__item {
    padding: 16px 24px;
  }
  .alert__imgbox {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }
  .alert__esc {
    display: block;
    margin: 40px auto;
  }
}
</style>
