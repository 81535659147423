import { ref } from 'vue'
import fetchData from '@/mixins/fetchData'

const isOpenPostNew = ref(false)
const postId = ref('')
const postTitle = ref('')
const postContent = ref('')
const postDate = ref('')
const postPreviewText = ref('')
const postSelectedImgSrc = ref('')
const postImgFile = ref('')
const postBarbershop = ref('')
const postCities = ref('')
const { fetchAll } = fetchData()

export default () => {
  const togglePostNew = () => {
    if (isOpenPostNew.value) {
      isOpenPostNew.value = false
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      isOpenPostNew.value = true
    }
  }

  const formData = () => {
    const formData = new FormData()
    const data = {}

    data.title = postTitle.value
    if (postContent.value) data.content = postContent.value
    if (postPreviewText.value) data.previewText = postPreviewText.value

    if (postDate.value == '') {
      data.date = '2022-09-01'
    } else {
      data.date = postDate.value
    }

    if (postBarbershop.value) data.barbershop = postBarbershop.value
    if (postCities.value) data.cities = postCities.value

    formData.append('data', JSON.stringify(data))
    formData.append('files.previewImage', postImgFile.value[0])

    return formData
  }

  const createPost = async () => {
    try {
      const data = await fetch(`https://barberkuzma.ru:1414/posts`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: formData()
      })

      await data.json()

      return true
    } catch (error) {
      return false
    } finally {
      togglePostNew()
      fetchAll()
    }
  }

  const removePost = async () => {
    try {
      const data = await fetch(`https://barberkuzma.ru:1414/posts/${postId.value}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      })

      await data.json()

      return true
    } catch (error) {
      return false
    } finally {
      fetchAll()
    }
  }

  const updatePost = async () => {
    try {
      const data = await fetch(`https://barberkuzma.ru:1414/posts/${postId.value}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        body: formData()
      })

      await data.json()

      return true
    } catch (error) {
      return false
    } finally {
      fetchAll()
    }
  }

  return {
    isOpenPostNew,
    postId,
    postTitle,
    postContent,
    postDate,
    postPreviewText,
    postSelectedImgSrc,
    postImgFile,
    postBarbershop,
    postCities,
    togglePostNew,
    createPost,
    removePost,
    updatePost
  }
}
