<template>
  <div class="social">
    <InstagramSvg v-if="label == 'Instagram'" class="social__item" />
    <WhatsappSvg v-else-if="label == 'WhatsApp'" class="social__item" />
    <TelegramSvg v-else-if="label == 'Telegram'" class="social__item" />
    <YoutubeSvg v-else-if="label == 'YouTube'" class="social__item" />
    <TiktokSvg v-else-if="label == 'TikTok'" class="social__item" />
    <VkontakteSvg v-else-if="label == 'VKontakte'" class="social__item" />

    <div class="social__center">
      <div v-if="isEmptySocial" class="social__name">{{ label }}</div>
      <div v-if="isNotEmptySocial" class="social__name social__name--light">
        {{ social[keyName] }}
      </div>
      <CustomInput
        v-if="edited"
        class="input--full"
        :label="''"
        :placeholder="'Имя пользователя'"
        :value="social[keyName] || ''"
        @inputs="newValue => (social[keyName] = newValue)"
      />
    </div>

    <div>
      <button v-if="isEmptySocial" class="button button--filled" @click="edited = true">
        <div class="button__add">Добавить</div>
        <div class="button__addMobile">+</div>
      </button>

      <button v-if="edited" class="button button--plain" @click="saveHandler">
        <div class="button__add">Сохранить</div>
        <TickSvg class="button__addMobile" />
      </button>

      <EditSvg v-if="isNotEmptySocial" class="social__icon" @click="edited = true" />
    </div>
  </div>
</template>

<script setup>
import InstagramSvg from '@/components/svg/settings/InstagramSvg'
import WhatsappSvg from '@/components/svg/settings/WhatsappSvg'
import TelegramSvg from '@/components/svg/settings/TelegramSvg'
import YoutubeSvg from '@/components/svg/settings/YoutubeSvg'
import TiktokSvg from '@/components/svg/settings/TiktokSvg'
import VkontakteSvg from '@/components/svg/settings/VkontakteSvg'
import EditSvg from '@/components/svg/settings/EditSvg'
import TickSvg from '@/components/svg/TickSvg'

import CustomInput from '@/components/CustomInput'

import { ref, computed } from 'vue'

const props = defineProps({
  value: {
    type: Object,
    default () {
      return {}
    }
  },
  label: {
    type: String,
    default () {
      return null
    }
  },
  keyName: {
    type: String,
    default () {
      return null
    }
  }
})

const emit = defineEmits(['click'])

const social = props.value || {}
const edited = ref(false)

const isEmptySocial = computed(() => {
  return (
    (!edited.value && social[props.keyName] === '') ||
    (!edited.value && social[props.keyName] === null) ||
    (!edited.value && typeof social[props.keyName] === 'undefined')
  )
})

const isNotEmptySocial = computed(() => {
  return (
    !edited.value &&
    social[props.keyName] !== '' &&
    social[props.keyName] !== null &&
    typeof social[props.keyName] !== 'undefined'
  )
})

const saveHandler = () => {
  edited.value = false
  emit('click', social)
}
</script>

<style scoped>
.social {
  display: flex;
  align-items: center;
  height: 42px;
  margin-bottom: 32px;
}
.social__center {
  flex: 1;
  margin: 0 16px;
}
.social__item {
  opacity: 0.6;
}
.social__name {
  color: #ffffff;
  opacity: 0.6;
}
.social__name--light {
  opacity: 1;
}
.social__icon {
  display: block;
  cursor: pointer;
}

.button__add {
  display: block;
}
.button__addMobile {
  display: none;
}

@media only screen and (max-width: 600px) {
  .social {
    margin-bottom: 27px;
  }

  .button__add {
    display: none;
  }
  .button__addMobile {
    display: block;
    fill: #ffda7e;
  }
}
</style>
