<template>
  <section v-if="isOpenModal" class="modal modalRecords" @click.self="toggleModal">
    <div class="modal__content">
      <div class="modal__header">
        <div class="modal__headbox">
          <div class="modal__title">{{ courseName }}</div>
          <div class="modal__number">
            + {{ records.length }}
            {{ conjugate(records.length, 'участник', 'участника', 'участников') }}
          </div>
        </div>

        <CloseSvg class="closeSvg closeSvg--white modal__close" @click="toggleModal" />
      </div>

      <div v-for="record in records" :key="record.id" class="modal__item">
        <router-link class="modal__imgbox" :to="`/profile/${record.users_permissions_user.profile.id}`">
          <img
            v-if="record.users_permissions_user.profile.profileImg != null"
            v-lazy="
              `https://barberkuzma.ru:1414${record.users_permissions_user.profile.profileImg.formats.thumbnail.url}`
            "
            class="modal__img"
          />
          <ProfileSvg class="modal__icon" />
        </router-link>

        <div class="modal__details">
          <router-link class="modal__name" :to="`/profile/${record.users_permissions_user.profile.id}`"
            >{{ record.users_permissions_user.profile.profileName }}
            {{ record.users_permissions_user.profile.profileSurname }}</router-link
          >
          <div class="modal__company">
            {{ record.users_permissions_user.profile.profileBarbershop.name }}
          </div>
        </div>

        <div v-if="isEditor">
          <div v-if="record.confirmed" class="modal__options" @click="unconfirmBarber(record.id)">
            <div class="modal__option modal__option--remove">удалить</div>
            <ConfirmSvg />
          </div>
          <div v-else class="modal__options" @click="confirmBarber(record.id)">
            <div class="modal__option modal__option--confirm">подтвердить</div>
            <WaitSvg />
          </div>
        </div>

        <div v-else class="modal__statuses">
          <ConfirmSvg v-if="record.confirmed" />
          <WaitSvg v-else />

          <Tooltip class="modal__tooltip">
            <div v-if="record.confirmed">Подтвердил присутствие</div>
            <div v-else>Ждет подтверждения</div>
          </Tooltip>
        </div>
      </div>

      <CloseModalSvg class="modal__esc" @click="toggleModal" />
    </div>
  </section>
</template>

<script setup>
// Svg
import CloseModalSvg from '@/components/svg/CloseModalSvg'

// Components
import Tooltip from '@/components/Tooltip'
import ConfirmSvg from '@/components/svg/education/ConfirmSvg'
import WaitSvg from '@/components/svg/education/WaitSvg'
import ProfileSvg from '@/components/svg/ProfileSvg'
import CloseSvg from '@/components/svg/CloseSvg'

// Helpers
import conjugate from '@/mixins/conjugate'
import useModalRecords from '@/mixins/useModalRecords'

import { ref, computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const emit = defineEmits(['updateCourseRecords'])
let { records, courseName, isOpenModal, toggleModal } = useModalRecords()

const isEditor = computed(() => {
  return store.state.user.positions.filter(position => position.id == 6).length > 0
})

const confirmBarber = async recordId => {
  let data = await fetch(`https://barberkuzma.ru:1414/course-records/${recordId}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ confirmed: true })
  })

  let response = await data.json()
  emit('updateCourseRecords', recordId, response)
}

const unconfirmBarber = async recordId => {
  let data = await fetch(`https://barberkuzma.ru:1414/course-records/${recordId}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ confirmed: false })
  })

  let response = await data.json()
  emit('updateCourseRecords', recordId, response)
}
</script>

<style scoped>
.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.modal__content {
  position: relative;
  width: 706px;
  background: #343c42;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.45);
  border-radius: 8px;
  cursor: default;
}
.modal__header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 9px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.modal__title {
  letter-spacing: 0.02em;
  padding-right: 16px;
}
.modal__headbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-right: 16px;
}
.modal__number {
  font-size: 14px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.6);
  white-space: nowrap;
}
.modal__close {
  cursor: pointer;
  transition: 0.3s;
}
.modal__close:hover {
  fill: #ffda7e;
  transition: 0.3s;
}
.modal__item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 48px;
}
.modal__item:last-of-type {
  margin-bottom: 16px;
}
.modal__imgbox {
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  background: #56636d;
  margin-right: 24px;
  overflow: hidden;
}
.modal__img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}
.modal__icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.modal__name {
  display: block;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}
.modal__company {
  font-size: 14px;
  color: #ffffff;
  opacity: 0.6;
}
.modal__details {
  flex: 1;
  padding-right: 16px;
}
.modal__statuses {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.modal__statuses:hover .tooltip {
  cursor: default;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
}
.modal__tooltip {
  position: absolute;
  right: 0;
  top: 40px;
}
.modal__options {
  display: flex;
  align-items: center;
}
.modal__option {
  cursor: pointer;
  margin-right: 8px;
}
.modal__option--remove {
  transition: 0.3s;
}
.modal__option--remove:hover {
  color: #f26464;
  transition: 0.3s;
}
.modal__option--confirm {
  transition: 0.3s;
}
.modal__option--confirm:hover {
  color: #27ae60;
  transition: 0.3s;
}
.modal__esc {
  display: none;
}

@media only screen and (max-width: 600px) {
  .modal {
    overflow: scroll;
  }
  .modal__content {
    position: absolute;
    left: 0;
    top: calc(100% - 340px);
    width: 100%;
    border-right: none;
    border-left: none;
    border-bottom: none;
    border-radius: 20px 20px 0px 0px;
  }
  .modal__header {
    justify-content: space-between;
    padding: 16px;
    color: #ffda7e;
  }
  .modal__headbox {
    display: block;
    flex: unset;
  }
  .modal__title {
    padding-right: 0;
    margin-bottom: 8px;
  }
  .modal__item {
    padding: 16px 24px;
  }
  .modal__imgbox {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }
  .modal__esc {
    display: block;
    margin: 40px auto;
  }
}
</style>
