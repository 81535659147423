<template>
  <div class="input">
    <div v-if="label" class="input__label">{{ label }}</div>
    <input
      v-if="type == 'date'"
      class="input__input"
      type="date"
      :value="value"
      @input="$emit('inputs', $event.target.value)"
    />
    <input
      v-else
      v-maska="mask"
      class="input__input"
      :class="{ 'input__input--alert': alert }"
      :placeholder="placeholder"
      :value="value"
      @input="$emit('inputs', $event.target.value)"
    />

    <div v-if="alert" class="input__alert">{{ alert }}</div>
    <svg v-if="type == 'date'" class="input__icon" width="22" height="21" viewBox="0 0 22 21" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.1543 2.20734C6.96637 2.28884 6.83774 2.41225 6.73081 2.61368C6.65781 2.75117 6.64124 2.86531 6.62832 3.31874L6.61289 3.85974L6.38099 3.88471C4.97936 4.03565 3.87239 4.85761 3.36995 6.12052C3.13149 6.71996 3.13444 6.64867 3.13481 11.7339C3.13497 14.7164 3.15023 16.4909 3.17718 16.6615C3.38931 18.0061 4.37648 19.1165 5.70075 19.4999L6.02608 19.5942H11.0095H15.9929L16.3182 19.4999C17.4932 19.1597 18.3767 18.2846 18.7488 17.0922L18.8639 16.7231L18.8754 11.9285C18.8854 7.72353 18.8789 7.08799 18.8224 6.76053C18.6342 5.6684 17.8906 4.69892 16.8693 4.21387C16.5514 4.06293 16.0374 3.92512 15.638 3.88377L15.4061 3.85974L15.3906 3.31874C15.3741 2.73845 15.3214 2.56188 15.1042 2.3593C14.7709 2.04832 14.1959 2.07437 13.8725 2.41508C13.6769 2.62119 13.6345 2.78792 13.6345 3.35065V3.86466H11.0095H8.38448V3.35065C8.38448 2.78792 8.34207 2.62119 8.14647 2.41508C7.89816 2.15349 7.48029 2.06596 7.1543 2.20734ZM6.62081 6.08184C6.62081 6.69342 6.67044 6.87135 6.90714 7.10801C7.33895 7.53982 8.00722 7.43568 8.2769 6.89452C8.37615 6.69535 8.38448 6.63797 8.38448 6.15349V5.62833H11.0095H13.6345V6.15349C13.6345 6.6392 13.6426 6.69498 13.743 6.89649C14.0166 7.44553 14.7407 7.54204 15.1444 7.08328C15.3495 6.85035 15.3982 6.65725 15.3982 6.07655V5.61775L15.6083 5.64564C16.1052 5.71155 16.5691 6.00329 16.8377 6.41874C17.1006 6.82562 17.1208 6.9451 17.1208 8.09673V9.11466H11.007H4.89327L4.90595 8.01646L4.91866 6.91823L5.05893 6.6335C5.21369 6.31936 5.47926 6.02799 5.76182 5.86241C5.95689 5.7481 6.31988 5.63555 6.50802 5.63104L6.62081 5.62833V6.08184ZM17.1208 13.6394C17.1208 16.6384 17.1222 16.6181 16.8898 17.0153C16.7623 17.2333 16.405 17.5708 16.1804 17.6854C15.7784 17.8905 15.7765 17.8906 11.0095 17.8906C6.24248 17.8906 6.24055 17.8905 5.8386 17.6854C5.61396 17.5708 5.25663 17.2333 5.12915 17.0153C4.89676 16.6181 4.89815 16.6384 4.89815 13.6394V10.8783H11.0095H17.1208V13.6394Z"
        fill="#606569"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'CustomInput',
  props: ['label', 'placeholder', 'value', 'type', 'mask', 'alert']
}
</script>

<style>
input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.input {
  position: relative;
  margin: 0 8px 0 0;
  transition: 0.3s;
}
.input__label {
  font-size: 14px;
  line-height: 21px;
  opacity: 0.6;
  margin-bottom: 8px;
}
.input__input {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 4px;
  height: 37px;
  background: transparent;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  padding: 0 16px;
  transition: 0.3s;
}
.input__input:hover {
  border-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 4px rgba(255, 218, 126, 0.4);
  transition: 0.3s;
}
.input__input:focus {
  border-color: #ffda7e;
  box-shadow: none;
  transition: 0.3s;
}
.input__input--alert {
  border-color: #f27c7c;
  transition: 0.3s;
}
.input__alert {
  color: #ff7a7a;
  margin-top: 6px;
}
.input__icon {
  position: absolute;
  right: 16px;
  bottom: 8px;
  pointer-events: none;
}
</style>
