<template>
  <section class="profile">
    <div class="profile__header">Профиль</div>

    <div class="profile__body">
      <div class="profile__profile">
        <div class="profile__imgbox">
          <img v-if="selectedImgSrc != ''" class="profile__img" :src="selectedImgSrc" />
          <img
            v-else-if="profile.profileImg === Object(profile.profileImg)"
            v-lazy="'https://barberkuzma.ru:1414' + profile.profileImg.url"
            class="profile__img"
          />
          <svg class="profile__person" width="68" height="68" viewBox="0 0 68 68" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M33.6776 30.0697C38.3275 30.0697 42.097 26.3002 42.097 21.6502C42.097 17.0003 38.3275 13.2307 33.6776 13.2307C29.0276 13.2307 25.2581 17.0003 25.2581 21.6502C25.2581 26.3002 29.0276 30.0697 33.6776 30.0697ZM33.6776 36.0836C41.6489 36.0836 48.111 29.6216 48.111 21.6502C48.111 13.6789 41.6489 7.2168 33.6776 7.2168C25.7062 7.2168 19.2441 13.6789 19.2441 21.6502C19.2441 29.6216 25.7062 36.0836 33.6776 36.0836Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M53.3964 60.1392C52.2999 57.0665 50.6991 54.293 48.698 51.9704C46.6974 49.6484 44.3382 47.8246 41.7636 46.5869C39.1901 45.3496 36.4435 44.7178 33.6781 44.7178C30.9126 44.7178 28.166 45.3496 25.5925 46.5869C23.0179 47.8246 20.6587 49.6484 18.6581 51.9704C16.657 54.293 15.0562 57.0665 13.9597 60.1392L8.41957 57.9489C9.76954 54.1662 11.7547 50.7106 14.2742 47.7862C16.7942 44.8613 19.8018 42.523 23.1336 40.9212C26.4665 39.3188 30.0504 38.489 33.6781 38.489C37.3057 38.489 40.8896 39.3188 44.2225 40.9212C47.5543 42.523 50.5619 44.8613 53.0819 47.7863C55.6014 50.7106 57.5866 54.1662 58.9365 57.9489L53.3964 60.1392Z"
              fill="white"
            />
          </svg>
          <div class="profile__cover">
            Загрузить<br />фото
            <input class="profile__files" type="file" @change="selectImage" />
          </div>
        </div>

        <div class="profile__content">
          <div v-if="profile.profileName || profile.profileSurname" class="profile__name">
            {{ profile.profileName }} {{ profile.profileSurname }}
          </div>
          <div v-else class="profile__name">{{ store.state.user.username }}</div>
          <div class="profile__statusbox">
            <div class="profile__label">Статус</div>
            <input
              class="profile__status"
              :value="profile.profileStatus"
              :placeholder="'Установить статус'"
              @input="setStatus($event.target.value)"
            />
          </div>

          <div class="profile__positions">
            <div v-for="position in store.state.user.positions" :key="position.id" class="profile__position">
              {{ position.position }}
            </div>
          </div>

          <div class="ycli">
            <div>
              <span class="ycli__label">YClinet ID:</span>
              <span v-if="profile.profileYclientId == null" class="ycli__value">Получить у администратора</span>
              <span v-else class="ycli__value">{{ profile.profileYclientId }}</span>
            </div>
            <svg class="ycli__icon" width="38" height="38" viewBox="0 0 38 38" fill="#9EA3A5">
              <path
                d="M19.8 19.8H18.2V15H19.8V19.8ZM19.8 23H18.2V21.4H19.8V23ZM19 11C17.9494 11 16.9091 11.2069 15.9385 11.609C14.9679 12.011 14.086 12.6003 13.3431 13.3431C11.8429 14.8434 11 16.8783 11 19C11 21.1217 11.8429 23.1566 13.3431 24.6569C14.086 25.3997 14.9679 25.989 15.9385 26.391C16.9091 26.7931 17.9494 27 19 27C21.1217 27 23.1566 26.1571 24.6569 24.6569C26.1571 23.1566 27 21.1217 27 19C27 17.9494 26.7931 16.9091 26.391 15.9385C25.989 14.9679 25.3997 14.086 24.6569 13.3431C23.914 12.6003 23.0321 12.011 22.0615 11.609C21.0909 11.2069 20.0506 11 19 11Z"
              />
            </svg>
            <div class="ycli__notification">
              <svg class="ycli__triangle" width="15" height="15" viewBox="0 0 15 15">
                <path d="M14.9189 0.347168V14.7036H0.5625L14.9189 0.347168Z" fill="#1D232A" />
              </svg>
              Это уникальный идентификатор, который помогает системе связать между собой действия пользователя и собрать
              статистику.
            </div>
          </div>
        </div>
      </div>

      <div class="profile__inputs">
        <CustomInput
          class="profile__item profile__item--full"
          :label="'Имя'"
          :placeholder="'Имя'"
          :value="profile.profileName"
          @inputs="value => (profile.profileName = value)"
        />
        <CustomInput
          class="profile__item profile__item--full"
          :label="'Фамилия'"
          :placeholder="'Фамилия'"
          :value="profile.profileSurname"
          @inputs="value => (profile.profileSurname = value)"
        />
        <CustomSelectbox
          class="profile__item profile__item--half"
          :label="'Пол'"
          :key-name="'name'"
          :value="profile.profileGender"
          :name="'gender'"
          :options="[
            { id: 'man', name: 'Мужской' },
            { id: 'woman', name: 'Женский' }
          ]"
          @inputs="value => (profile.profileGender = value)"
        />
        <CustomInput
          class="profile__item profile__item--half"
          :label="'Дата рождения'"
          :value="profile.profileBirthDate"
          :type="'date'"
          @inputs="value => (profile.profileBirthDate = value)"
        />
        <CustomInput
          class="profile__item profile__item--full"
          :label="'Мобильный телефон'"
          :placeholder="'Мобильный телефон'"
          :value="profile.profilePhoneNumber"
          :mask="'+7 (###) ###-##-##'"
          @inputs="value => (profile.profilePhoneNumber = value)"
        />
        <CustomSelectbox
          class="profile__item profile__item--full"
          :label="'Место работы'"
          :key-name="'name'"
          :value="profile.profileBarbershop"
          :name="'barbershop'"
          :options="barbershops"
          @inputs="value => barbershopsHandler(value)"
        />
        <CustomSelectbox
          v-if="profile.profileBarbershop != '' && cities != ''"
          class="profile__item profile__item--full"
          :label="'Город'"
          :key-name="'name'"
          :value="profile.profileCity"
          :name="'city'"
          :options="cities"
          @inputs="value => citiesHandler(value)"
        />
        <CustomSelectboxMulti
          v-if="profile.profileCity != '' && adresses != ''"
          class="profile__item profile__item--full"
          :label="'Адрес места работы'"
          :key-name="'adress'"
          :value="profile.profileAdresses"
          :name="'adress'"
          :options="adresses"
          @update-items="value => (profile.profileAdresses = [...value])"
        />
      </div>

      <Button class="profile__button" :loading="loading" :success="success" @click="updateHandler" />
    </div>
  </section>
</template>

<script setup>
// Components
import CustomInput from '@/components/CustomInput'
import CustomSelectbox from '@/components/CustomSelectbox'
import CustomSelectboxMulti from '@/components/CustomSelectboxMulti'
import Button from '@/components/Button'

// Composables
import buttonMixin from '@/mixins/buttonMixin'

import { ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const profile = store.state.user.profile || {}
const currentPosition = store.state.user.position || {}
const barbershops = ref('')
const cities = ref('')
const adresses = ref('')
const files = ref('')
const uploadedImgId = ref('')
const selectedImgSrc = ref('')
let { loading, success, buttonLoad, buttonSuccess } = buttonMixin()

const selectImage = e => {
  files.value = e.target.files
  selectedImgSrc = URL.createObjectURL(e.target.files[0])
}

const deleteOldImage = async () => {
  const data = await fetch(`https://barberkuzma.ru:1414/upload/files/${profile.profileImg.id}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
  const response = await data.json()
  console.log(response)
}

const uploadImage = async () => {
  const formData = new FormData()
  formData.append('files', files.value[0])

  const data = await fetch(`https://barberkuzma.ru:1414/upload`, {
    method: 'POST',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    body: formData
  })

  const response = await data.json()
  console.log(response)

  uploadedImgId.value = response[0].id
  if (profile.profileImg === Object(profile.profileImg)) deleteOldImage()
  updateServerImage()
}

const updateServerImage = async () => {
  profile.profileImg = uploadedImgId

  const data = await fetch(`https://barberkuzma.ru:1414/users/${store.state.user.id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ profile: profile })
  })

  const response = await data.json()
  console.log(response)
}

const barbershopsHandler = value => {
  profile.profileBarbershop = profile.profileCity = null
  profile.profileAdress = null
  cities.value = ''
  adresses.value = ''
  if (value !== null) getCitiesData()
}

const citiesHandler = value => {
  profile.profileCity = value
  profile.profileAdress = null
  adresses.value = ''
  if (value !== null) getAdressesData()
}

const adressesHandler = value => {
  profile.profileAdress = value
}

const setStatus = newVal => {
  profile.profileStatus = newVal
}

const getBarbershopsData = async () => {
  const data = await fetch(`https://barberkuzma.ru:1414/barbershops/`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
  const response = await data.json()
  barbershops.value = response
}

const getCitiesData = async () => {
  const data = await fetch(`https://barberkuzma.ru:1414/cities?barbershops.id=${profile.profileBarbershop.id}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  })
  const response = await data.json()
  cities.value = response
}

const getAdressesData = async () => {
  const data = await fetch(
    `https://barberkuzma.ru:1414/adresses?barbershop.id=${profile.profileBarbershop.id}&city=${profile.profileCity.id}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }
  )
  const response = await data.json()
  adresses.value = response
}

const updateServerData = async () => {
  buttonLoad()

  await fetch(`https://barberkuzma.ru:1414/users/${store.state.user.id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ profile: profile })
  })

  buttonSuccess()
}

const updateHandler = () => {
  updateServerData()
  if (selectedImgSrc.value != '') uploadImage()
}

getBarbershopsData()
if (profile.profileBarbershop !== null) getCitiesData()
if (profile.profileCity !== null) getAdressesData()
</script>

<style scoped>
.ycli {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ycli__label {
  color: #ffda7e;
  margin-right: 6px;
  font-size: 14px;
  line-height: 21px;
}
.ycli__value {
  font-size: 14px;
  line-height: 21px;
}
.ycli__icon {
  cursor: pointer;
  transition: 0.3s;
}
.ycli__icon:hover {
  fill: #ffda7e;
  transition: 0.3s;
}
.ycli__icon:hover ~ .ycli__notification {
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
}
.ycli__notification {
  padding: 16px;
  background: #1d232a;
  border-radius: 8px;
  color: white;
  position: absolute;
  width: 100%;
  right: 0;
  top: calc(100% + 20px);
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
}
.ycli__triangle {
  position: absolute;
  right: 22px;
  top: -14px;
}

@media only screen and (max-width: 600px) {
  .ycli {
    display: flex;
    align-items: center;
  }
}
</style>
