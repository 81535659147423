<template>
  <section class="profile">
    <div class="profile__header">Интересы</div>

    <div class="profile__body">
      <div class="interests">
        <div class="interests__title">Рекомендуем</div>
        <div class="interests__text">
          Укажи свои любимые занятия, хобби, то чем ты увлекаешься в свободное от работы время. Пишите всё, чем
          захочется поделиться.
        </div>
        <img class="interests__img1" src="@/assets/img/settings/interests1.svg" />
        <img class="interests__img2" src="@/assets/img/settings/interests2.svg" />
      </div>

      <div class="profile__inputs">
        <CustomInput
          class="profile__item profile__item--full"
          :label="'Любимые инструменты в работе'"
          :placeholder="'Расческа...'"
          :value="interests.interestsTools"
          @inputs="value => (interests.interestsTools = value)"
        />
        <CustomInput
          class="profile__item profile__item--full"
          :label="'Любимая стрижка'"
          :placeholder="'Небрежная стрижка пикси с прямой челкой...'"
          :value="interests.interestsHaircut"
          @inputs="value => (interests.interestsHaircut = value)"
        />
        <CustomInput
          class="profile__item profile__item--full"
          :label="'Любимые барберы-инфлюенсеры'"
          :placeholder="'Джош Лламонака...'"
          :value="interests.interestsInfluencer"
          @inputs="value => (interests.interestsInfluencer = value)"
        />
        <CustomInput
          class="profile__item profile__item--full"
          :label="'Любимое место покушать :)'"
          :placeholder="'Кафе, пиццерии, шаурмечные...'"
          :value="interests.interestsCafe"
          @inputs="value => (interests.interestsCafe = value)"
        />
        <CustomInput
          class="profile__item profile__item--full"
          :label="'Татуировки на теле'"
          :placeholder="'Если есть, то сколько и какая твоя любимая...'"
          :value="interests.interestsTatoo"
          @inputs="value => (interests.interestsTatoo = value)"
        />
        <CustomTextarea
          :label="'Хобби'"
          :value="interests.interestsHobby"
          :placeholder="'Видеоигры, кемпинг, языки, блогинг...'"
          @inputs="value => (interests.interestsHobby = value)"
        />
      </div>

      <Button class="profile__button" :loading="loading" :success="success" @click="updateServerData" />
    </div>
  </section>
</template>

<script setup>
import CustomInput from '@/components/CustomInput'
import CustomTextarea from '@/components/CustomTextarea'
import Button from '@/components/Button'
import buttonMixin from '@/mixins/buttonMixin'

import { useStore } from 'vuex'

const store = useStore()
const interests = store.state.user.interests
let { loading, success, buttonLoad, buttonSuccess } = buttonMixin()

const updateServerData = async () => {
  buttonLoad()

  await fetch(`https://barberkuzma.ru:1414/users/${store.state.user.id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ interests: interests })
  })

  buttonSuccess()
}
</script>

<style scoped>
.interests {
  position: relative;
  padding: 16px;
  background: linear-gradient(92.65deg, rgba(0, 0, 0, 0) 47.26%, rgba(0, 0, 0, 0.2) 100%), #ffda7e;
  border-radius: 12px;
  margin-bottom: 32px;
}
.interests__title {
  color: #15191c;
  font-weight: 600;
  margin-bottom: 8px;
}
.interests__text {
  width: 400px;
  color: #343c42;
  font-size: 14px;
  line-height: 21px;
}
.interests__img1 {
  position: absolute;
  right: 0;
  bottom: 0;
}
.interests__img2 {
  position: absolute;
  top: 0;
  right: 110px;
}

@media only screen and (max-width: 600px) {
  .interests__img1 {
    display: none;
  }
  .interests__img2 {
    display: none;
  }
  .interests__text {
    width: 100%;
  }
}
</style>
