<template>
  <div class="tooltip">
    <svg class="tooltip__triangle" width="15" height="15" viewBox="0 0 15 15">
      <path
        d="M14.9189 0.347168V14.7036H0.5625L14.9189 0.347168Z"
        fill="#1D232A"
      />
    </svg>
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.tooltip {
  padding: 16px;
  background: #1d232a;
  border-radius: 8px;
  color: white;
  width: fit-content;
  z-index: 1;
  white-space: nowrap;
  font-size: 14px;
  line-height: 21px;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
}
.tooltip__triangle {
  position: absolute;
  right: 12px;
  top: -14px;
}
</style>
