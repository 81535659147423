<template>
  <section
    v-if="
      (router.currentRoute.value.name != 'Login' &&
        router.currentRoute.value.name != 'Menu' &&
        router.currentRoute.value.name != 'City' &&
        router.currentRoute.value.name != 'Adress' &&
        router.currentRoute.value.name != 'Net' &&
        router.currentRoute.value.name != 'Profile' &&
        router.currentRoute.value.name != 'Settings' &&
        isMobile) ||
        !isMobile
    "
    class="header"
  >
    <div class="header__stat">
      <TrendSvg class="header__trend" />
      <div v-if="servicesAmount != null" class="header__persent header__persent--orange">
        {{ servicesAmount.toLocaleString() }} ₽
      </div>
      <Loader v-else class="loader--small" />
      <div class="header__statname"><span class="header__word">продано</span> услуг</div>
    </div>

    <div class="header__stat">
      <TrendSvg class="header__trend header__trend--green" />
      <div v-if="productsAmount != null" class="header__persent header__persent--green">
        {{ productsAmount.toLocaleString() }} ₽
      </div>
      <Loader v-else class="loader--small loader--green" />
      <div class="header__statname"><span class="header__word">продано</span> товаров</div>
    </div>

    <div class="header__widgets">
      <AlertSvg class="header__widget" />
      <LocationSvg class="header__widget" />
      <ProfileSvg class="header__widget" />
    </div>
  </section>
</template>

<script setup>
import TrendSvg from '@/components/svg/TrendSvg'
import AlertSvg from '@/components/svg/AlertSvg'
import LocationSvg from '@/components/svg/LocationSvg'
import ProfileSvg from '@/components/svg/ProfileSvg'

import Loader from '@/components/Loader'
import isMobile from '@/mixins/isMobile'

import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const router = useRouter()
const store = useStore()
const servicesAmount = ref(null)
const productsAmount = ref(null)
const servicesAmountArray = ref([])
const productsAmountArray = ref([])

const barberIds = []

watch(
  () => store.state.user,
  async (newQuestion, oldQuestion) => {
    handler()
  }
)

const getBarberIds = async yclientAdressId => {
  const data = await fetch(`https://api.yclients.com/api/v1/company/${yclientAdressId}/staff`, {
    headers: {
      Accept: 'application/vnd.yclients.v2+json',
      Authorization: 'Bearer n662dd37yhgcbd8dfk4j,53df7e5ed2e5569a00247df4755a94a0'
    }
  })

  const response = await data.json()
  const barber = response.data.filter(
    barber => barber.name == `${store.state.user.profile.profileName} ${store.state.user.profile.profileSurname}`
  )

  if (barber.length > 0) {
    const barberId = barber[0]['id']
    const companyId = barber[0]['company_id']

    barberIds.push([barberId, companyId])
  }
}

const getData = async barberId => {
  const date = new Date()
  const currFirstDay = new Date(date.getFullYear(), date.getMonth(), 2).toISOString().split('T')[0]
  const currLastDay = new Date().toISOString().split('T')[0]

  const data = await fetch(
    `https://api.yclients.com/api/v1/transactions/${barberId[1]}?count=10000&master_id=${barberId[0]}&start_date=${currFirstDay}&end_date=${currLastDay}`,
    {
      headers: {
        Accept: 'application/vnd.yclients.v2+json',
        Authorization: 'Bearer 8k5zukp5jhd764zff887, 07198a4de480ca51d2ffb01994597a47'
      }
    }
  )

  const response = await data.json()

  servicesAmountArray.value.push(
    response.data
      .filter(item => item.expense.id == 5)
      .map(item => item.amount)
      .reduce((a, b) => a + b, 0)
  )

  productsAmountArray.value.push(
    response.data
      .filter(item => item.expense.id == 7)
      .map(item => item.amount)
      .reduce((a, b) => a + b, 0)
  )
}

const handler = async () => {
  if (store.state.user.profile.profileAdresses.length > 0) {
    const adresses = store.state.user.profile.profileAdresses
    const yclientAdressIds = adresses.map(adress => adress.yclientId).filter(yclientId => yclientId != null)

    if (yclientAdressIds.length > 0) {
      for (const yclientAdressId of yclientAdressIds) {
        await getBarberIds(yclientAdressId)
      }
    }

    if (barberIds.length > 0) {
      for (const barberId of barberIds) {
        await getData(barberId)
      }

      servicesAmount.value = Object.values(servicesAmountArray.value).reduce((a, b) => a + b)
      productsAmount.value = Object.values(productsAmountArray.value).reduce((a, b) => a + b)
    } else {
      servicesAmount.value = 0
      productsAmount.value = 0
    }
  } else {
    servicesAmount.value = 0
    productsAmount.value = 0
  }
}
</script>

<style scoped>
.header {
  height: 80px;
  background: #343c42;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
}
.header__stat {
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-right: 23px;
}
.header__trend {
  margin-right: 5px;
}
.header__trend--green {
  stroke: #4bde97;
}
.header__persent {
  font-size: 14px;
  line-height: 16px;
  color: #ffda7e;
}
.header__persent--orange {
  color: #ffda7e;
}
.header__persent--green {
  color: #4bde97;
}
.header__persent--red {
  color: #f26464;
}
.header__statname {
  font-size: 14px;
  line-height: 16px;
  color: #b9b9b9;
  margin-left: 10px;
}
.header__widgets {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.header__widget {
  margin-right: 34px;
  transition: 0.3s;
}
.header__widget:hover {
  cursor: pointer;
  transform: rotate(35deg);
  transition: 0.3s;
}
.header__widget:last-of-type {
  margin-right: 0;
}

@media only screen and (max-width: 600px) {
  .header {
    height: 58px;
    overflow: hidden;
    margin: 8px 0 16px 0;
    padding: 0 16px;
    justify-content: unset;
  }
  .header__widgets {
    display: none;
  }
  .header__word {
    display: none;
  }
}
</style>
