<template>
  <section class="menu">
    <router-link :class="['menu__item', { 'menu__item--active': $route.fullPath.split('/')[1] == '/team' }]" to="/team">
      <TeamSvg />
      <div class="menu__text">Команда управления</div>
      <TriangleRightSvg />
    </router-link>

    <router-link
      :class="['menu__item', { 'menu__item--active': $route.fullPath.split('/')[1] == '/competition' }]"
      to="/competition"
    >
      <CompetitionSvg />
      <div class="menu__text">Конкурсы</div>
      <TriangleRightSvg />
    </router-link>

    <router-link
      :class="['menu__item', { 'menu__item--active': $route.fullPath.split('/')[1] == '/settings' }]"
      to="/settings"
    >
      <SettingsSvg />
      <div class="menu__text">Настройки</div>
      <TriangleRightSvg />
    </router-link>

    <router-link
      :class="['menu__item', { 'menu__item--active': $route.fullPath.split('/')[1] == '/support' }]"
      to="/support"
    >
      <SupportSvg />
      <div class="menu__text">Поддержка</div>
      <TriangleRightSvg />
    </router-link>

    <div class="menu__item menu__item--last" @click="logOut">
      <LogoutSvg />
      <div class="menu__text">Выход</div>
    </div>
  </section>
</template>

<script setup>
import authorize from '@/mixins/authorize'

import TriangleRightSvg from '@/components/svg/TriangleRightSvg'
import TeamSvg from '@/components/svg/sidebar/TeamSvg'
import CompetitionSvg from '@/components/svg/sidebar/CompetitionSvg'
import SettingsSvg from '@/components/svg/sidebar/SettingsSvg'
import SupportSvg from '@/components/svg/sidebar/SupportSvg'
import LogoutSvg from '@/components/svg/sidebar/LogoutSvg'

import { useRouter } from 'vue-router'

const router = useRouter()

const logOut = () => {
  localStorage.removeItem('token')
  router.push({ path: '/login' })
}

authorize()
</script>

<style scoped>
.menu {
  padding: 24px 16px 0 16px;
}
.menu__item {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  color: white;
  padding: 16px;
  margin-bottom: 16px;
}
.menu__item--last {
  margin-bottom: 0;
}
.menu__text {
  flex: 1;
  margin: 0 8px;
}
</style>
