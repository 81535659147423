<template>
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.29582 2.07194C4.20872 2.11911 4.1235 2.20899 4.07665 2.30314C4.0005 2.45625 4 2.5169 4 11.9957C4 21.4783 4.00046 21.5351 4.07673 21.6884C4.16302 21.8618 4.38261 22 4.57174 22C4.76087 22 4.98046 21.8618 5.06676 21.6884C5.14234 21.5365 5.14348 21.467 5.14348 17.058V12.5819H7.43045H9.71742V13.3288C9.71742 14.1796 9.74882 14.2954 10.0225 14.4531L10.1661 14.5358H14.8603C19.4526 14.5358 19.5576 14.5342 19.7013 14.459C19.8923 14.3591 20.0284 14.0901 19.9949 13.8789C19.9824 13.7996 19.6474 12.9038 19.2507 11.8882L18.5293 10.0417L19.2507 8.19521C19.6474 7.17962 19.9824 6.2838 19.9949 6.20451C20.0284 5.99328 19.8923 5.7243 19.7013 5.62445C19.5585 5.54973 19.4599 5.54758 16.1606 5.54758H12.7667V4.80351C12.7667 3.9397 12.7328 3.81355 12.4628 3.67228C12.3152 3.5951 12.2451 3.59362 8.72792 3.59362H5.14348V3.0254C5.14348 2.53304 5.13323 2.43659 5.06676 2.30298C4.92611 2.02032 4.5818 1.91715 4.29582 2.07194ZM11.6232 8.08774V11.4095H8.38335H5.14348V8.08774V4.766H8.38335H11.6232V8.08774ZM17.9659 8.3221C17.622 9.20326 17.3406 9.97708 17.3406 10.0417C17.3406 10.1063 17.622 10.8802 17.9659 11.7613L18.5912 13.3635H15.1377H11.6842L12.1634 12.8652C12.427 12.5912 12.6705 12.3107 12.7047 12.2419C12.7602 12.1299 12.7667 11.8338 12.7667 9.4184V6.71997H15.679H18.5912L17.9659 8.3221Z"
      fill="white"
    />
  </svg>
</template>
