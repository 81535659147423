import { ref } from 'vue'

const loading = ref(false)
const success = ref(false)

export default () => {
  const buttonLoad = () => {
    loading.value = true
  }

  const buttonSuccess = () => {
    loading.value = false
    success.value = true
    setTimeout(() => (success.value = false), 2000)
  }

  return {
    loading: loading,
    success: success,
    buttonLoad: buttonLoad,
    buttonSuccess: buttonSuccess
  }
}
